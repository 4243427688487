import { IconButton, Flex } from "@chakra-ui/react";
import { cloneElement } from "react";
import { useCallback, useMemo, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useAuth } from "../hooks/useAuth";
import { useGame } from "../hooks/useGame";
import { useStartUp } from "../hooks/useStartUps";

interface StartupCardContainerProps {
  children?: JSX.Element;
}

export function StartupInfoCardContainer({
  children
}: StartupCardContainerProps) {
  const { user } = useAuth();
  const [currentStartUp, setCurrentStartUp] = useState(0);
  const { gameRoom, myStartUp, handleGetStartUpTotalPoints } = useGame();
  const { startUps } = useStartUp();

  const gameStartUps = useMemo(() => {
    if (!gameRoom.startUps) { 
      return;
    }
    const gameStartUpsUids = gameRoom.startUps.map(s => s.uid);
    const startUpsFiltered = startUps.filter(s => gameStartUpsUids.includes(s.uid));
    return startUpsFiltered.map(s => {
      return {
        ...s,
        totalPoints: handleGetStartUpTotalPoints(s.uid),
      }
    });
  }, [gameRoom.startUps, handleGetStartUpTotalPoints, startUps]);

  const handleNavigateStartUp = useCallback((action: 'next' | 'previous') => {
    if (action === 'next') {
      setCurrentStartUp(prev => prev + 1);
    }
    if (action === 'previous') {
      setCurrentStartUp(prev => prev - 1);
    }
  }, []);

  return user && children && gameStartUps ? (
    <Flex>
      {user.isAdmin && <IconButton
        display="flex"
        alignSelf="center"
        fontSize="x-large"
        aria-label="previous startup"
        icon={<FaChevronLeft />}
        variant="ghost"
        color="#F3FF63"
        _hover={{
          bgColor: "none",
        }}
        onClick={() => handleNavigateStartUp('previous')}
        disabled={currentStartUp === 0}
      />}
      {cloneElement(children, { startUpInfo: user.isAdmin ? gameStartUps[currentStartUp] : myStartUp }, null)}
      {user.isAdmin && <IconButton
        display="flex"
        alignSelf="center"
        fontSize="x-large"
        aria-label="next startup"
        icon={<FaChevronRight />}
        onClick={() => handleNavigateStartUp('next')}
        disabled={currentStartUp === gameStartUps.length - 1}
        variant="unstyled"
        p={1}
        _hover={{
          bgColor: "none",
        }}
        color="#F3FF63"
      />}
    </Flex>
  ) : null;
}
