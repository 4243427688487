import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithEmailAndPassword, updateProfile } from "firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useCallback } from "react";
import { FC, useEffect } from "react";
import { createContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { auth, firestore, signInWithPopup } from '../services/firebase';

export type User = {
  id: string;
  name: string;
  avatar: string;
  email: string;
  isAdmin: boolean;
}

type AuthContextType = {
  user?: User;
  signInWithGoogle: () => Promise<void>;
  signUpWithEmailAndPassword: (email: string, password: string, displayName: string) => Promise<void>;
  signInWithCredentials: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  loading: boolean;
}

export const AuthContext = createContext({} as AuthContextType);

export const AuthContextProvider: FC = ({ children }) => {
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!user && !loading && !location.pathname.includes('/login')) {
      history.push('/login');
    }
  }, [history, loading, location.pathname, user]);

  const handleCheckIsAdmin = useCallback(async (user: Omit<User, 'isAdmin'>) => {
    const userState = {
      id: user.id,
      name: user.name,
      avatar: user.avatar,
      email: user.email,
      isAdmin: false,
    }
    const q = query(
      collection(firestore, 'users'),
      where('email', '==', user.email),
    );
    const docs = await getDocs(q);
    docs.forEach(doc => {
      const userData = doc.data();
      if (userData && userData.isAdmin) {
        userState.isAdmin = true;
      }
    });
    setUser(userState);
    setLoading(false);
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        const { displayName, photoURL, uid, email } = user;

        if (!displayName || !email) {
          throw new Error('Missing information from Auth.')
        }
        handleCheckIsAdmin({
          id: uid,
          name: displayName,
          avatar: photoURL ?? '',
          email,
        });
        return;
      }
      setLoading(false);
    });

    return () => unsubscribe()
  }, [handleCheckIsAdmin]);

  async function signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      setLoading(true);
      if (result.user) {
        const { displayName, photoURL, uid, email } = result.user;

        if (!displayName || !email) {
          throw new Error('Missing information from Auth.');
        }
        await handleCheckIsAdmin({
          id: uid,
          name: displayName,
          avatar: photoURL ?? '',
          email,
        });
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false);
    }
  }

  async function signUpWithEmailAndPassword(email: string, password: string, displayName: string) {
    setLoading(true);
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      const user = auth.currentUser;
      if (user) {
        await updateProfile(user, {
          displayName,
        });
        await handleCheckIsAdmin({
          id: user.uid,
          name: displayName,
          avatar: '',
          email,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function signInWithCredentials(email: string, password: string) {
    setLoading(true);
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      if (result.user) {
        const { displayName, photoURL, uid, email } = result.user;

        if (!displayName || !email) {
          throw new Error('Missing information from Auth.');
        }
        await handleCheckIsAdmin({
          id: uid,
          name: displayName,
          avatar: photoURL ?? '',
          email,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function signOut() {
    await auth.signOut();
    setUser(undefined);
  }

  return (
    <AuthContext.Provider value={{
      user,
      signInWithGoogle,
      signUpWithEmailAndPassword,
      signInWithCredentials,
      signOut,
      loading
    }}>
      {children}
    </AuthContext.Provider>
  )
}