import { Route, Switch } from "react-router-dom";
import { QuizInfoScreen } from "../../pages/QuizInfoScreen";
import { QuizScreen } from "../../pages/QuizScreen/QuizScreen";

interface QuizRoutesProps { }

export function UserQuizRoutes(props: QuizRoutesProps) {
  return (
    <Switch>
      <Route exact path="/rooms/:id/quiz/:quizId" component={QuizInfoScreen} />
      <Route path="/rooms/:id/quiz/:quizId/question/:questionId" component={QuizScreen} />
    </Switch>
  );
}
