import { Box, Button, ButtonProps, IconButtonProps, CircularProgress, CircularProgressLabel, CircularProgressProps, Flex, IconButton, Popover, PopoverContent, PopoverTrigger, Text, VStack, Tooltip } from "@chakra-ui/react";
import { ReactElement } from "react";
import { FaHistory, FaPauseCircle, FaPlayCircle, FaStopCircle } from "react-icons/fa";
import { useAuth } from "../hooks/useAuth";
import { useGame } from "../hooks/useGame";

interface TimerProps extends CircularProgressProps { }

interface TimeButtonsProps extends ButtonProps {
  label?: string;
}

interface TimerControlProps extends IconButtonProps {
  icon: ReactElement;
  label?: string;
}

function TimerControl({ icon, label, ...rest }: TimerControlProps) {
  return (
    <Tooltip label={label}>
      <IconButton
        display="flex"
        variant="unstyled"
        color="gray.50"
        icon={icon}
        {...rest}
      />
    </Tooltip>
  )
}

function TimeButtons({ label, children, ...rest }: TimeButtonsProps) {
  return (
    <Button px={2} w={12} h={4} {...rest}>
      {children ? (
        <>
          {children}
        </>
      ) : (
        <Text fontSize={12} as="span">
          {label}
        </Text>
      )}
    </Button>
  )
}

export function Timer({
  ...rest
}: TimerProps) {
  const { user } = useAuth();
  const { timer: { getFormattedTime, timer, seconds, handleControlTimer, handleAddTime } } = useGame();
  // useEffect(() => {
  //   if (timer.status === 'running') {
  //     document.title = process.env.REACT_APP_TITLE + ' | ' + getFormattedTime(seconds) ;
  //   }
  //   if (timer.status === 'off') {
  //     document.title = process.env.REACT_APP_TITLE ?? 'Félix 2000';
  //   }
  // }, [getFormattedTime, seconds, timer.status]);
  return user?.isAdmin ? (
    <Popover>
      <PopoverTrigger>
        <Box as="button">
          <CircularProgress
            capIsRound
            value={(timer.secondsAmount - seconds) * 100 / timer.secondsAmount}
            trackColor="rgba(63, 119, 162, 0.5)"
            color="green.200"
            thickness="4px"
            size="100px"
            {...rest}
          >
            <CircularProgressLabel fontFamily="Righteous" color="gray.50">
              {getFormattedTime(seconds)}
            </CircularProgressLabel>
          </CircularProgress>
        </Box>
      </PopoverTrigger>
      <PopoverContent
        mt={-5}
        w={170}
        border="none"
        bgColor="transparent"
      >
        <VStack spacing="sm">
          <Flex
            justifyContent="space-evenly"
            flexDir="row"
            h={8}
            w="100%"
          >
            <TimeButtons
              label="+30s"
              onClick={() => handleAddTime(30)}
            />
            <TimeButtons
              alignSelf="flex-end"
              label="+1min"
              onClick={() => handleAddTime(60)}
            />
            <TimeButtons
              label="+5min"
              onClick={() => handleAddTime(300)}
            />
          </Flex>
          <Flex
            justifyContent="space-evenly"
            flexDir="row"
            h={8}
            w="100%"
          >
            <TimerControl
              aria-label="play timer"
              color="green.300"
              label="Contar"
              onClick={() => handleControlTimer({ action: 'start' })}
              icon={<FaPlayCircle fontSize={28} />}
            />
            <TimerControl
              aria-label="pause timer"
              label="Pausar"
              onClick={() => handleControlTimer({ action: 'pause' })}
              icon={<FaPauseCircle fontSize={28} />}
            />
            <TimerControl
              aria-label="stop timer"
              color="red.300"
              label="Parar"
              onClick={() => handleControlTimer({ action: 'stop' })}
              icon={<FaStopCircle fontSize={28} />}
            />
            <TimerControl
              aria-label="reset timer"
              label="Resetar"
              onClick={() => handleControlTimer({ action: 'reset' })}
              icon={<FaHistory fontSize={28} />}
            />
          </Flex>
        </VStack>
      </PopoverContent>
    </Popover>
  ) : (
    <CircularProgress
      sx={{
        strokeLinecap: 'round',
      }}
      value={(timer.secondsAmount - seconds) * 100 / timer.secondsAmount}
      trackColor="rgba(63, 119, 162, 0.5)"
      color="green.200"
      size="100px"
      thickness="4px"
      {...rest}
    >
      <CircularProgressLabel fontFamily="Righteous" color="gray.50">
        {getFormattedTime(seconds)}
      </CircularProgressLabel>
    </CircularProgress>
  );
}
