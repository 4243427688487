import { Button, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import { FaSignOutAlt } from "react-icons/fa";
import { useAuth } from "../hooks/useAuth";

export function ConfirmLeaveModal() {
  const { signOut } = useAuth();
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Button
        w="100%"
        color="gray.50"
        colorScheme="purple"
        onClick={onOpen}
        leftIcon={<FaSignOutAlt />}
      >
        SAIR
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent bgColor="rgba(255,255,255,0.2)">
          <ModalCloseButton color="gray.50" />
          <ModalHeader fontSize="2xl" letterSpacing="1px" color="gray.50" fontFamily="Bebas Neue">SAIR</ModalHeader>
          <ModalBody color="gray.50">
            <Text textAlign="center">Deseja sair da sala?</Text>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <HStack>
              <Button colorScheme="cyan" onClick={onClose} color="gray.50">CANCELAR</Button>
              <Button colorScheme="red" onClick={signOut}>SAIR</Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
