import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useQuiz } from "../../hooks/useQuiz";
import { Button, Fade, VStack, Box, Flex, HStack, Heading, Text } from "@chakra-ui/react";
import { Choice } from "./Choice";
import { QuestionType } from "../../contexts/QuizContext";
import { CardTopNotch } from "../../components/CardTopNotch";
import { useAuth } from "../../hooks/useAuth";
import { onValue, ref, update } from "firebase/database";
import { database } from "../../services/firebase";
import { useGame } from "../../hooks/useGame";
import { IsLoaded } from "../../IsLoaded";

interface QuizScreenProps { }

interface AnswerType {
  selected: number;
  correct: boolean;
}

export function QuizScreen(props: QuizScreenProps) {
  const { user } = useAuth();
  const { quiz, questions, quizLoading } = useQuiz();
  const { gameRoom, roomId, timer: { seconds, timer, handleControlTimer }, myStartUp } = useGame();
  const choicesRef = useRef(null);
  const params = useParams<{ quizId: string, questionId: string }>();

  const [question, setQuestion] = useState<QuestionType>();
  const [selectedChoice, setSelectedChoice] = useState<number>();
  const [answer, setAnswer] = useState<AnswerType>();
  const [loading, setLoading] = useState(false);
  const [valueOnAnswer, setValueOnAnswer] = useState<number>();

  const bonusFactor = useMemo(() => {
    if (!myStartUp) return 1;
    if (gameRoom.purchases && gameRoom.purchases[myStartUp.uid]) {
      return (gameRoom.purchases?.[myStartUp.uid] || []).reduce((acc, curr) => acc * (1 + curr.bonus / 100), 1);
    }
    return 1;
  }, [gameRoom.purchases, myStartUp]);

  const questionValue = (question?.value ?? 300) * bonusFactor;

  const answersDone = useMemo(() => {
    const quiz = Object.entries(gameRoom?.quiz ?? {}).find(([key, _]) => key === params.quizId);
    if (!quiz) {
      return [];
    }
    const answers = Object.entries(quiz[1]).filter(([_, value]) => Object.keys(value).includes(params.questionId));
    return answers;
  }, [gameRoom?.quiz, params.questionId, params.quizId]);

  const questionTeamMismatchFactor = useMemo(() => {
    if (!gameRoom.participants || !myStartUp?.uid) {
      return 1;
    }

    const participantsByStartup = Object.entries(gameRoom.participants).reduce((acc, [_, participant]) => {
      if (!acc[participant.startUpUid]) {
        acc[participant.startUpUid] = 1;
        return acc;
      }
      acc[participant.startUpUid] += 1
      return acc
    }, {} as Record<string, number>);

    const maxNumberOfPlayers = Math.max(...Object.values(participantsByStartup));
    const myStartupNumberOfPlayers = participantsByStartup[myStartUp.uid];

    return maxNumberOfPlayers / myStartupNumberOfPlayers;

  }, [gameRoom.participants, myStartUp?.uid]);

  const questionPercentValue = useMemo(() => {
    const minTime = 5 + 5;
    const maxTime = 35 - 5;
    if (seconds <= minTime) {
      return 100;
    }
    if (seconds > maxTime) {
      return 0.3 * 100;
    }
    return Math.round((-70 * (seconds - minTime) / (maxTime - minTime)) + (100 + 70 / (maxTime - minTime)));
  }, [seconds]);

  const handleAnswer = useCallback(async () => {
    setLoading(true);
    setSelectedChoice(undefined);
    try {
      if (!roomId || !params.quizId || !user || !question) {
        setLoading(false);
        return;
      }
      const myParticipantsRef = Object.entries(gameRoom.participants ?? {}).find(([_, value]) => value.userId === user.id);
      const isCorrect = selectedChoice !== undefined ? question.choices[selectedChoice].isCorrect : false;
      if (!myParticipantsRef) {
        setLoading(false);
        return;
      }
      setValueOnAnswer(questionPercentValue);
      await update(ref(database), {
        [`rooms/${roomId}/quiz/${params.quizId}/${user.id}/${question.id}`]: {
          selected: selectedChoice ?? -1,
          correct: isCorrect,
        },
        [`rooms/${roomId}/participants/${myParticipantsRef[0]}/score`]: (myParticipantsRef[1].score ?? 0) + (isCorrect ? questionPercentValue * questionTeamMismatchFactor * questionValue / 100 : 0),
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [roomId, params.quizId, user, question, gameRoom.participants, selectedChoice, questionPercentValue, questionTeamMismatchFactor, questionValue]);

  const bgColor = useMemo(() => {
    if (answer !== undefined && !answer.correct) {
      return "rgba(254,108,108,0.3)";
    }
    if (answer !== undefined && answer.correct) {
      return "rgba(72, 187, 120, 0.3)";
    }
    return "rgba(255,255,255,0.2)"
  }, [answer]);

  const shouldShowChoices = useMemo(() => {
    if (user?.isAdmin || answer) {
      return true;
    }
    return seconds > 5;
  }, [answer, seconds, user?.isAdmin]);

  useEffect(() => {
    setQuestion(questions.find(q => q.id === params.questionId));
    setSelectedChoice(undefined);
    setValueOnAnswer(undefined);
  }, [params.questionId, questions]);

  useEffect(() => {
    if (!roomId || !params.quizId || !user || !question) {
      return;
    }
    const valRef = ref(database, `rooms/${roomId}/quiz/${params.quizId}/${user.id}/${question.id}`);
    const unsubscribe = onValue(valRef, (snapshot) => {
      const data = snapshot.val() as AnswerType | null;
      if (data) {
        const { selected, correct } = data;
        setAnswer({
          selected,
          correct,
        });
      } else {
        setAnswer(undefined);
      }
    });
    return () => unsubscribe();
  }, [params.quizId, question, roomId, user]);

  useEffect(() => {
    async function startQuestionTimer() {
      if (user && user?.isAdmin) {
        await handleControlTimer({ action: 'start', value: 35 });
      }
    }
    startQuestionTimer();
  }, [handleControlTimer, user, question]);

  useEffect(() => {
    if (seconds >= timer.secondsAmount && !answer && choicesRef.current !== null) {
      handleAnswer();
    }
  }, [answer, handleAnswer, seconds, timer.secondsAmount]);

  useEffect(() => {
    async function stopQuestionTimer() {
      if (user && user?.isAdmin && (answersDone.length >= (Object.keys(gameRoom?.participants ?? {})).length)) {
        await handleControlTimer({ action: 'stop' });
      }
    }
    stopQuestionTimer();
  }, [answersDone, gameRoom?.participants, handleControlTimer, user])

  return quiz.questionsRef ? (
    <IsLoaded externalLoading={quizLoading}>
      <Box px={4} w="100%" maxW={900} m="0 auto">
        <Flex mt={7}>
          <HStack
            bgColor={{ base: 'none', sm: bgColor }}
            backdropFilter={{ base: 'none', sm: "blur(5px)" }}
            borderRadius="20px 0 0 0"
            px={4}
            py={2}
            zIndex={10}
          >
            <Heading
              fontSize="x-large"
              textTransform="uppercase"
              color="gray.50"
            >
              {`${quiz.title} (${quiz.questionsRef.findIndex(q => q === params.questionId) + 1}/${quiz.questionsRef.length})`}
            </Heading>
          </HStack>
          <Box display={{ base: 'none', sm: 'block' }}>
            <CardTopNotch fill={bgColor} />
          </Box>
        </Flex>
        {question && question.body && question.choices && <Box
          bgColor={bgColor}
          backdropFilter="blur(5px)"
          borderRadius={{ base: "20px", sm: "0 20px 20px 20px" }}
          boxShadow="8px 8px 8px rgba(0, 0, 0, 0.25)"
          px={8}
          pt={10}
          pb={4}
        >
          <Heading
            fontSize="lg"
            textTransform="uppercase"
            color="gray.50"
          >
            {question.body}
          </Heading>
          {shouldShowChoices && <Fade in={shouldShowChoices}>
            <VStack maxW={700} m="1rem auto" ref={choicesRef}>
              {question.choices.map((c, i) => <Choice
                onClick={() => setSelectedChoice(i)}
                isSelected={selectedChoice === i}
                answer={answer?.selected}
                isCorrectChoice={c.isCorrect}
                key={i}
                index={i}
                text={c.text}
                amountSelected={answersDone.reduce((acc, [_, value]) => {
                  return acc + Object.entries(value)
                    .filter(([questionId, values]) => values.selected === i && questionId === params.questionId).length;
                }, 0)}
              />)}
            </VStack>
          </Fade>}
          {!shouldShowChoices &&
            <Flex justify="center" m="2rem auto">
              <Heading fontFamily="Righteous" fontSize="xxx-large" color="gray.50">{5 - seconds}</Heading>
            </Flex>}
          {user && !user.isAdmin && !answer && (selectedChoice !== undefined) && <Flex>
            <Button isLoading={loading} colorScheme="cyan" color="gray.50" ml="auto" onClick={handleAnswer}>
              RESPONDER
            </Button>
          </Flex>}
          {user && answer && (answersDone.length < Object.keys(gameRoom?.participants ?? {}).length) && (seconds < timer.secondsAmount) && <Flex justify="center">
            <Text fontWeight="bold" color="yellow.300">
              AGUARDANDO A RESPOSTA DOS DEMAIS PARTICIPANTES...
            </Text>
          </Flex>}
        </Box>}
        <Box px={4} maxW={500} m="0 auto" mt={4} w="100%">
          <Text color="gray.50" fontWeight="bold">Valor do acerto</Text>
          <Box
            w={`${valueOnAnswer ? valueOnAnswer : questionPercentValue}%`}
            bgColor="cyan.200"
            boxShadow="0px 0px 10px rgba(190, 255, 243, 0.6)"
            h={6}
            px={1}
            borderRadius={12}
            textAlign="center"
            transition="all 0.3s"
          >
            <Text color="purple.900" fontWeight="bold">{Math.round(questionValue * questionTeamMismatchFactor * (valueOnAnswer ? valueOnAnswer : questionPercentValue) / 100)}</Text>
          </Box>
        </Box>
      </Box>
    </IsLoaded>
  ) : null;
}
