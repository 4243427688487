import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import Lottie from 'react-lottie';
import { useAuth } from "../../hooks/useAuth";

import rocket from '../../assets/36517-astronaut.json';
import { Loading } from "../../components/Loading";
import { useHeader } from "../../hooks/useHeader";

interface UserLobbyProps { }

export function UserLobby(props: UserLobbyProps) {
  const { user } = useAuth();
  useHeader('Aguardando liberação');



  return (
    <VStack spacing={{ sm: 8, md: 1 }}>
      <Box m="0 auto" p="4rem 1rem 0rem" maxW="1120px" display={{ lg: 'flex' }}>
        <VStack align="left" spacing={10}>
          <Box>
            <Heading as="span" color="gray.50" fontWeight="regular" fontSize={40}>Olá{user?.name ? `, ` : ''}</Heading>
            <Heading as="span" color="pink.300" fontSize={40}>{user?.name ? `${user.name}` : ''}</Heading>
            <Heading as="span" color="gray.50" fontWeight="regular" fontSize={40}>.</Heading>
          </Box>
          <Box>
            <Heading color="gray.50" fontSize={32}>Bem vindo ao Startup Game!</Heading>
            <Heading color="gray.50" fontSize={24}>Nossa jornada gamificada do programa Sparks.</Heading>
          </Box>
          <Text color="gray.50" fontSize={20}>Este jogo irá te ajudar a compreender a vida de uma startup early-stage e também irá te preparar para o vocabulário startupês!</Text>
        </VStack>
        <Box w={[300, null, 500]} h={[270, null, 450]} m="-2rem auto 0">
          <Lottie
            options={{
              animationData: rocket,
              loop: true,
              autoplay: true,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height="100%"
            width="100%"
          />
        </Box>
      </Box>
      <Text
        align="center"
        fontSize={20}
        color="yellow.400"
        fontWeight="bold"
      >
        Você já vai entrar na sala. Aguarde a liberação do administrador...
      </Text>
      <Loading />
    </VStack>
  );
}
