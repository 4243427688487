import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { AuthContextProvider } from './contexts/AuthContext';
import { GameBoardContextProvider } from './contexts/GameBoardContext';
import { StartUpProvider } from './contexts/StartUpContext';
import { Routes } from './routes/Routes';
import { theme } from './styles/theme';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <AuthContextProvider>
          <StartUpProvider>
            <GameBoardContextProvider>
              <Routes />
            </GameBoardContextProvider>
          </StartUpProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
