import { HStack } from "@chakra-ui/react";
import { FaStar } from "react-icons/fa";

interface PlacementStarsProps {
  position: number;
}

export function PlacementStars({
  position
}: PlacementStarsProps) {
  const starsBg = "linear-gradient(to bottom, #1A3A49, #1A3A49 50%, #162F3F 50%, #162F3F)";
  return (
    <HStack
      bg={starsBg}
      color="cyan.200"
      m="0 auto"
      p="0.4rem 1rem"
      bgSize="100% 5px"
      borderWidth={4}
      borderColor="purple.600"
      borderBottomWidth={0}
      borderRadius="8px 8px 0px 0px"
    >
      <FaStar filter="drop-shadow(0px 0px 6px #a8ffff)" />
      <FaStar filter="drop-shadow(0px 0px 6px #a8ffff)" style={{ marginTop: '-6px' }} opacity={position <= 2 ? 1 : 0.2} />
      <FaStar filter="drop-shadow(0px 0px 6px #a8ffff)" opacity={position <= 1 ? 1 : 0.2} />
    </HStack>
  );
}