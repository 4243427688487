import { FormControl, FormLabel, Input as ChakraInput, InputProps as ChakraInputProps } from '@chakra-ui/react';

interface InputProps extends ChakraInputProps {
  name: string;
  label?: string;
}

export function Input({
  name, label, type, ...rest
}: InputProps) {
  return (
    <FormControl>
      {!!label && <FormLabel htmlFor={name} fontWeight="light">{label}</FormLabel>}
      <ChakraInput
        id={name}
        name={name}
        type={type}
        color="gray.900"
        _focus={{
          _placeholder: {
            color: "pink.500",
          },
          borderColor: "pink.500",
        }}
        bgColor="white"
        variant="filled"
        _hover={{
          bgColor: 'white'
        }}
        _placeholder={{
          fontWeight: 'light',
        }}
        {...rest}
      />
    </FormControl>
  );
}