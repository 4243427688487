import { Flex } from "@chakra-ui/react";
import { Redirect, Route, Switch } from "react-router-dom";
import { GameContextProvider } from "../contexts/GameContext";
import { AdminRoomRoutes } from "./Room/AdminRoomRoutes";
import { CreateRoom } from "../pages/CreateRoom";
import { Forbidden } from "../pages/Forbidden";
import { GameRoom } from "../pages/GameRoom";
import { LoginRouter } from "../pages/Login/LoginRoutes";
import { UserRoomRoutes } from "./Room/UserRoomRoutes";
import { PrivateRoute } from "./PrivateRoute";
import { HeaderContextProvider } from "../contexts/HeaderContext";
import { GameBoardContextProvider } from "../contexts/GameBoardContext";

interface RoutesProps { }

export function Routes(props: RoutesProps) {
  return (
    <Flex
      w="100vw"
      h="100vh"
      flexDirection="column"
      align="center"
      bgImage="url(/assets/background-space.png)"
      bgRepeat="no-repeat"
      bgAttachment="fixed"
      bgSize="cover"
    >
      <Flex
        w="100vw"
        h="100vh"
        flexDirection="column"
        overflow="auto"
      >
        <Switch>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          <Route path="/login" component={LoginRouter} />
          <Route path="/forbidden" component={Forbidden} />
          <Route path="/enter-room" component={GameRoom} />
          <Route>
            <HeaderContextProvider>
              <GameBoardContextProvider>
                <Switch>
                  <PrivateRoute path="/admin/create-room" component={CreateRoom} />
                  <PrivateRoute path="/admin/rooms/:id">
                    <GameContextProvider>
                      <AdminRoomRoutes />
                    </GameContextProvider>
                  </PrivateRoute>
                  <Route path="/rooms/:id">
                    <GameContextProvider>
                      <UserRoomRoutes />
                    </GameContextProvider>
                  </Route>
                </Switch>
              </GameBoardContextProvider>
            </HeaderContextProvider>
          </Route>
        </Switch>
      </Flex>
    </Flex >
  );
}
