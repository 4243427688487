import { Box, Flex, FormControlProps } from "@chakra-ui/react";
import { ReactNode } from "react";

interface FormBoxProps extends FormControlProps {
  children?: ReactNode
}

export function FormBox({
  children, ...rest
}: FormBoxProps) {
  return (
    <Flex
      bgColor="#B2B9C2"
      m="auto"
      borderRadius={5}
      filter="drop-shadow(-4px 4px 4px rgba(0, 0, 0, 0.25))"
      flexDirection="column"
    >
      <Flex flex="1" justifyContent="space-between" p={2}>
        <Box w="12px" minH="12px" borderRadius="50%" bgColor="#8F969D" />
        <Box w="12px" minH="12px" borderRadius="50%" bgColor="#8F969D" />
      </Flex>
      <Flex
        bgColor="white"
        p={8}
        borderRadius={5}
        w={340}
        mx={6}
        justify="center"
      >
        <Flex as="form" flex="1" flexDirection="column" {...rest}>
          {children}
        </Flex>
      </Flex>
      <Flex flex="1" justifyContent="space-between" p={2}>
        <Box w="12px" minH="12px" borderRadius="50%" bgColor="#8F969D" />
        <Box w="12px" minH="12px" borderRadius="50%" bgColor="#8F969D" />
      </Flex>
    </Flex>
  );
}
