import { Box, Flex, Heading, HStack } from "@chakra-ui/react";
import { ref, set } from "@firebase/database";
import { useCallback, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { CardContainer } from "../components/CardContainer";
import { StartupInfoCardContainer } from "../components/StartupInfoCardContainer";
import { database } from "../services/firebase";
import { ImprevisibilityCard } from "./ImprevisibilityCard";

interface WelcomeScreenProps {
  imprevisibilityIndex?: number;
  imprevisibilityTip?: string;
}

export function ImprevisibilityScreen({
  imprevisibilityIndex, imprevisibilityTip,
}: WelcomeScreenProps) {
  const params = useParams<{ id: string }>();
  const handleSetImprevisibilityIndex = useCallback(async (index: number) => {
    await set(ref(database, `rooms/${params.id}/imprevisibility`), index);
  }, [params.id]);

  useEffect(() => {
    if (imprevisibilityIndex !== undefined) {
      handleSetImprevisibilityIndex(imprevisibilityIndex + 1);
    }
  }, [handleSetImprevisibilityIndex, imprevisibilityIndex]);
  return (
    <>
      <Flex maxW={720} w="100%" flexDir="column" m="1rem auto 0" px={2} flex={1} justify="center">
        <HStack m="0 auto">
          {imprevisibilityIndex !== undefined && (
            <StartupInfoCardContainer>
              <ImprevisibilityCard
                imprevisibilityId={imprevisibilityIndex}
                imprevisibilityTip={imprevisibilityTip}
              />
            </StartupInfoCardContainer>
          )}
          {imprevisibilityTip && (
            <CardContainer maxW={300}>
              <Box px={4}>
                <Heading color="gray.50" fontFamily="Bebas Neue">Dica</Heading>
                <Box color="gray.50" fontSize="large" dangerouslySetInnerHTML={{ __html: imprevisibilityTip }} />
              </Box>
            </CardContainer>
          )}
        </HStack>
      </Flex>
    </>
  );
}
