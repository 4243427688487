import { Box, HStack, Heading, VStack, Image } from "@chakra-ui/react";
import { useMemo } from "react";
import { AvatarCircle } from "../../components/AvatarCircle";
import { CardContainer } from "../../components/CardContainer";
import { format } from "../../utils/format";
import { PlacementStars } from "./PlacementStars";
import coinImg from '../../assets/bluecoin.png';

interface PlacementCardProps {
  position: number;
  show?: boolean;
  onClick?: () => void;
  alignSelf?: string;
  startUp?: {
    name: string;
    score: number;
    imgSrc: string;
  };
}

export function PlacementCard({
  position, show = false, onClick, alignSelf, startUp,
}: PlacementCardProps) {
  const disabled = useMemo(() => !startUp, [startUp]);

  return (
    <Box sx={{ perspective: '1000px' }} borderRadius="20px" backdropFilter="blur(5px)" alignSelf={alignSelf} opacity={disabled ? 0.5 : 1}>
      <Box
        position="relative"
        minH={position === 0 ? '370px' : '330px'}
        w={position === 0 ? '300px' : '260px'}
        transition="transform 0.56s"
        transform={show ? 'rotateY(180deg)' : ''}
        sx={{ transformStyle: 'preserve-3d' }}
      >
        <CardContainer
          id={`${position}-place`}
          isBack
          cursor="pointer"
          position="absolute"
          onClick={disabled ? () => { } : onClick}
        >
          <HStack
            justify="center"
            opacity={show ? 0 : 1}
            transition={show ? "opacity 0.28s cubic-bezier(1,0,0,1)" : "opacity 0.28s cubic-bezier(1,0,0,1)"}
          >
            {!disabled && <Heading fontFamily="Bebas Neue" color="gray.50">{position + 1}° Lugar</Heading>}
          </HStack>
        </CardContainer>
        {startUp && <CardContainer
          id={`${position}-place`}
          cardTitle={<PlacementStars position={position + 1} />}
          position="absolute"
          transform="rotateY(180deg)"
        >
          <VStack h="100%">
            <HStack justify="center" position="relative" top="-32px">
              <Heading fontFamily="Bebas Neue" color="gray.50" fontSize="1rem">{position + 1}° Lugar</Heading>
            </HStack>
            <VStack justify="center" h="100%">
              <AvatarCircle boxSize="100px" src={startUp.imgSrc} name={startUp.name} />
              <Heading fontFamily="Bebas Neue" fontSize="3xl" color="gray.50">{startUp.name}</Heading>
              <HStack align="center">
                <Image src={coinImg} h={8} margin="0 auto" />
                <Heading fontSize="3xl" fontFamily="Bebas Neue" color="gray.50">$ {format(startUp.score)}</Heading>
              </HStack>
            </VStack>
          </VStack>
        </CardContainer>}
      </Box>
    </Box>
  );
}