import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  components: {
    Heading: {
      baseStyle: {
        letterSpacing: 'wider',
      }
    },
  },
  colors: {
    gray: {
      '900': '#171923',
      '600': '#4A5568',
      '400': '#BDBDBD',
      '300': '#CBD5E0',
      '100': '#EDF2F7',
    },
    green: {
      '600': '#2F855A',
      '400': '#48BB78',
      '200': '#B9F18D',
      '100': '#C6F6D5',
    },
    red: {
      '600': '#C53030',
      '500': '#DB4F00',
      '400': '#F56565',
      '100': '#FED7D7',
    },
    cyan: {
      '900': '#162F3F',
      '800': '#3F77A2',
      '600': '#4D9ACA',
      '300': '#6FC1E3',
      '200': '#6AFBFB',
      '100': '#E3F5FB',
    },
    purple: {
      '900': '#341C8C',
      '600': '#5F36AC',
      '500': '#7A47DA',
      '300': '#9575C9',
      '100': '#D1C4E7',
    },
    pink: {
      '700': '#FF57B2',
      '600': '#8C0BF7',
      '500': '#A200FD',
      '400': '#B100FF',
      '300': '#B666D9',
      '50': '#F3E5F8',
    },
    yellow: {
      '500':'#C68200',
      '400': '#FFDD63',
      '300': '#FFE600',
      '50': '#F3FF63'
    }
  },
  fonts: {
    body: 'Nunito, sans-serif',
    heading: 'Nunito, sans-serif',
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem",
  },
  styles: {
    global: {
      body: {
        bg: 'gray.900',
        color: 'gray.900',
      },
      ol: {
        listStylePosition: 'inside',
      },
      ul: {
        listStylePosition: 'inside',
      },
      li: {
        paddingLeft: '1rem',
        textAlign: 'left',
      }
    },
  }
});