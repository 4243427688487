import { Box, Flex, Heading } from "@chakra-ui/react";
import { ReactNode, createContext, useState } from "react";

export type HeaderContextType = {
  setTitle: (name: string) => void;
}

export const HeaderContext = createContext({} as HeaderContextType);

interface HeaderContextProviderProps {
  children?: ReactNode;
}

export function HeaderContextProvider({
  children,
}: HeaderContextProviderProps) {
  const [title, setTitle] = useState('');

  return (
    <HeaderContext.Provider value={{
      setTitle: (name: string) => setTitle(name),
    }}>
      <Box width="100%" minHeight="15px" bgColor="purple.500" />
      <Box width="100%" mb={-14}>
        <Flex m="0 auto" justify="center" align="center" bg="url('/assets/title-container.png') no-repeat" bgSize="contain" minHeight="52px" height="auto" maxWidth="600px" minWidth="300px">
          <Heading fontSize="28" color="purple.900" fontFamily="Bebas Neue">{title}</Heading>
        </Flex>
      </Box>
      {children}
    </HeaderContext.Provider>
  );
}