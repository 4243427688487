import { Avatar, Box, Button, Collapse, Grid, GridItem, HStack, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, SimpleGrid, Text, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { FaChevronDown, FaChevronUp, FaTimes } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { CopyControl } from "../components/CopyControl";
import { useGame } from "../hooks/useGame";
import { RoomParticipant, useGameRoom } from "../hooks/useGameRoom";
import { useStartUp } from "../hooks/useStartUps";

interface StartUpInfo {
  id: string;
  uid?: string;
  name?: string;
  participantCount?: number;
}

interface SelectStartUpCardProps {
  forbiddenOptions?: string[];
  startUpInfo: StartUpInfo;
  onDelete: (uid: string) => void;
  onUpdate: (value: StartUpInfo) => void;
  isWaitingPlayers?: boolean;
  name: string;
  participants?: RoomParticipant[];
  copyLink?: string;
}

export function SelectStartUpCard({
  startUpInfo, onDelete, onUpdate, forbiddenOptions = [], isWaitingPlayers = false, participants = [], name, copyLink,
}: SelectStartUpCardProps) {
  const { startUps } = useStartUp();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Box w="100%" p="1rem" borderRadius={8} bgGradient="linear(to-b, pink.400, purple.600)">
      {isWaitingPlayers ? (
        <HStack justify="space-between" color="gray.50">
          <Box>
            <Text as="span" fontWeight="bold">
              {`${name}: `}
            </Text>
            <Text as="span">
              {`${startUpInfo.name} (${participants.filter(p => p.startUpUid === startUpInfo.uid).length}/${Math.max(startUpInfo.participantCount ?? 0, participants.length)})`}
            </Text>
          </Box>
          {copyLink && (
            <CopyControl
              value={copyLink}
              tooltipText="Link para a sala com startup"
            />
          )}
        </HStack>
      ) : (
        <Grid templateColumns={{ base: "repeat(10, 1fr)", md: "repeat(20, 1fr)" }} templateRows="repeat(2, 1fr)" columnGap={3}>
          <GridItem colSpan={{ base: 8, md: 16 }}>
            <Text color="gray.50" fontWeight="bold">
              {name}
            </Text>
          </GridItem>
          <GridItem colSpan={{ base: 1, md: 3 }}>
            <Text color="gray.50" fontWeight="bold">
              Membros
            </Text>
          </GridItem>
          <GridItem colSpan={1} rowSpan={2} alignItems="center" mt="auto">
            <IconButton
              aria-label="Delete startup from list"
              display="flex"
              disabled={isWaitingPlayers}
              icon={<FaTimes />}
              textAlign="center"
              variant="unstyled"
              color="gray.50"
              bgColor="red.400"
              _hover={{
                bgColor: 'red.600',
              }}
              borderRadius="50%"
              size="sm"
              onClick={() => onDelete(startUpInfo.id)}
            />
          </GridItem>
          <GridItem colSpan={{ base: 8, md: 16 }}>
            <Select
              placeholder="Selecione uma startup"
              size="sm"
              bgColor="gray.50"
              icon={isWaitingPlayers ? <></> : undefined}
              pointerEvents={isWaitingPlayers ? 'none' : 'auto'}
              value={startUpInfo.uid}
              onChange={e => onUpdate({
                id: startUpInfo.id,
                uid: e.target.value,
                name: startUps.find(item => item.uid === e.target.value)?.name,
              })}
            >
              {startUpInfo.uid && !isWaitingPlayers && <option key={startUpInfo.id} value={startUpInfo.uid}>{startUpInfo.name}</option>}
              {startUps.filter(startUp => !forbiddenOptions.includes(startUp.uid)).map(startUp => (
                <option key={startUp.id} value={startUp.uid}>{startUp.name}</option>
              ))}
            </Select>
          </GridItem>
          <GridItem colSpan={{ base: 1, md: 3 }}>
            <Select
              placeholder="Selecione"
              size="sm"
              icon={isWaitingPlayers ? <></> : undefined}
              pointerEvents={isWaitingPlayers ? 'none' : 'auto'}
              bgColor="gray.50"
              value={startUpInfo.participantCount}
              onChange={e => onUpdate({
                id: startUpInfo.id,
                participantCount: Number(e.target.value),
              })}
            >
              {Array.from({ length: 15 }, (_, index) => index + 1).map(item => (
                <option key={item} value={item}>{item}</option>
              ))}
            </Select>
          </GridItem>
        </Grid>
      )}
      {isWaitingPlayers && (
        <Box display={{ base: 'none', sm: 'block' }}>
          <SimpleGrid minChildWidth="200px" mt={4} spacing="20px">
            {Array.from({ length: Math.max(startUpInfo.participantCount ?? 0, participants.length) ?? 0 }, (_, index) => index + 1)
              .map(p => {
                return (p <= participants.length) ? (
                  <Box
                    key={p}
                    minH="10px"
                    bgColor={"green.400"}
                    borderRadius="5px"
                  >
                    <Collapse in={isExpanded} animateOpacity>
                      <PlayerCard participant={participants[p - 1]} />
                    </Collapse>
                  </Box>
                ) : (
                  <Box
                    key={p}
                    minH="10px"
                    bgColor={"pink.300"}
                    borderRadius="5px"
                  />
                )
              })}
          </SimpleGrid>
          <Box w="100%" h="20px">
            <Button
              display="flex"
              variant="unstyled"
              m="0 auto"
              color="gray.50"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

type PlayerCardProps = {
  participant?: RoomParticipant;
}

function PlayerCard({
  participant,
}: PlayerCardProps) {
  const params = useParams<{ id: string }>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { gameRoom } = useGame();
  const { handleSwapUserTeam } = useGameRoom(params.id);
  const { startUpsHashMap } = useStartUp();
  const [newStartUpUid, setNewStartUpUid] = useState(participant?.startUpUid);

  return (
    <>
      <HStack as="button" justify="center" p={2} w="100%" onClick={onOpen}>
        <Avatar src={participant?.avatar} name={participant?.userDisplayName} size="sm" />
        <Text>{participant?.userDisplayName}</Text>
      </HStack>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay backdropFilter="blur(5px)" />
        <ModalContent bgColor="rgba(255,255,255,0.2)" backdropFilter="blur(5px)">
          <ModalCloseButton color="white" />
          <ModalHeader color="white">
            Trocar de time
          </ModalHeader>
          <ModalBody color="white">
            <Text dangerouslySetInnerHTML={{ __html: `Deseja enviar <strong>${participant?.userDisplayName}</strong> para qual time?` }} />
            <Select bgColor="white" color="black" value={newStartUpUid} onChange={e => setNewStartUpUid(e.target.value)}>
              {gameRoom.startUps?.map(s => <option key={s.uid} value={s.uid}>{startUpsHashMap[s.uid].name}</option>)}
            </Select>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <HStack>
              <Button
                color="white"
                onClick={onClose}
                variant="outline"
                _hover={{
                  bgColor: 'rgba(255,255,255,0.2)',
                }}
              >
                Cancelar
              </Button>
              <Button colorScheme="cyan" color="white" onClick={() => handleSwapUserTeam(participant?.userId ?? '', newStartUpUid ?? '')}>Trocar</Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
