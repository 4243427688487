import { Switch, Route, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { InfoScreen } from "../../pages/InfoScreen";
import { PresentationScreen } from "../../pages/PresentationScreen";
import { getPrismicClient } from "../../services/prismic";
import Prismic from '@prismicio/client';
import { RichText } from "prismic-dom";
import { useHeader } from "../../hooks/useHeader";
import { IsLoaded } from "../../IsLoaded";

interface PitchScreenPrismicResponse {
  data: {
    title: any;
    description: any;
    image: {
      alt: string;
      url: string;
    };
    value: number;
    tip?: any;
    scoringcriterias: {
      criteria: any;
    }[];
  };
}

interface PitchType {
  title: string;
  description: string;
  image: {
    alt: string;
    url: string;
  };
  value: number;
  tip?: string;
  criterias?: string[];
}

interface PitchRoutesProps { }

export function PitchRoutes(props: PitchRoutesProps) {
  const params = useParams<{ screenId: string }>();
  const [pitch, setPitch] = useState<PitchType>();
  useHeader(pitch?.title ?? '');

  useEffect(() => {
    async function fetchPitchScreen() {
      if (!params.screenId) {
        return;
      }
      const client = getPrismicClient();
      const response = await client.query(Prismic.Predicates.at('document.id', params.screenId));
      const results = (response.results as PitchScreenPrismicResponse[]);

      setPitch({
        title: RichText.asText(results[0].data.title),
        description: RichText.asHtml(results[0].data.description),
        image: {
          alt: results[0].data.image.alt,
          url: results[0].data.image.url,
        },
        value: results[0].data.value || 1000,
        tip: RichText.asHtml(results[0].data.tip ?? []),
        criterias: results[0].data.scoringcriterias.map(criteria => RichText.asText(criteria.criteria)),
      });
    }
    fetchPitchScreen();
  }, [params.screenId]);

  return (
    <IsLoaded externalLoading={!pitch}>
      <Switch>
        <Route path={["/admin/rooms/:id/pitch/:screenId/info", "/rooms/:id/pitch/:screenId/info"]}>
          <InfoScreen info={pitch} />
        </Route>
        <Route path={["/admin/rooms/:id/pitch/:screenId/presentation", "/rooms/:id/pitch/:screenId/presentation"]}>
          <PresentationScreen value={pitch?.value} tip={pitch?.tip} criterias={pitch?.criterias} image={pitch?.image.url} />
        </Route>
      </Switch>
    </IsLoaded>
  );
}
