import { Button, Flex, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaCaretDown } from "react-icons/fa";

interface MarketPlaceScoreModalProps {
  onClose: () => void;
  isOpen: boolean;
  onEvaluatePurchase: (evalDisc: number[], value: number) => void;
  startupName: string;
  getSavedEval: () => number[];
  marketValue?: number;
}

function getEvalValue(jValue: number, mValue: number, marketValue: number) {
  const jFactor = jValue === 2 ? 1 : 0.3 + jValue * 0.3;
  const mFactor = mValue === 2 ? 1 : 0.3 + mValue * 0.3;
  return Math.round((jFactor + mFactor) * marketValue / 2);
}

export function MarketPlaceScoreModal({
  onClose, isOpen, onEvaluatePurchase, startupName, getSavedEval, marketValue = 1500,
}: MarketPlaceScoreModalProps) {
  const [justificationValue, setJustifyValue] = useState(2);
  const [mordomyValue, setMordomyValue] = useState(2);
  
  useEffect(() => {
    const [jInit, mInit] = getSavedEval();
    setJustifyValue(jInit ?? 2);
    setMordomyValue(mInit ?? 2);
  }, [getSavedEval]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg="rgba(0, 0, 0, 0.85)" backdropFilter="blur(2px)" />
      <ModalContent bgColor="rgba(255,255,255,0.2)">
        <ModalCloseButton color="gray.50" />
        <ModalHeader fontSize="2xl" letterSpacing="1px" color="gray.50" fontFamily="Bebas Neue">
          <Text>
            Avalie a escolha da equipe
          </Text>
          <Text color="cyan.200">{startupName}</Text>
        </ModalHeader>
        <ModalBody color="gray.50">
          <Flex justify="space-between" align="center">
            <Text color="gray.50">
              Justificativa plausível 
            </Text>
            <Select
              h={8}
              value={justificationValue}
              maxW={120}
              color="gray.50"
              icon={<FaCaretDown fill="#6AFBFB" />}
              onChange={(e) => setJustifyValue(Number(e.target.value))}
              >
              <option value={2} style={{ backgroundColor: '#171923' }}>Ótimo</option>
              <option value={1} style={{ backgroundColor: '#171923' }}>Bom</option>
              <option value={0} style={{ backgroundColor: '#171923' }}>Ruim</option>
            </Select>
          </Flex>
          <Flex justify="space-between" align="center" mt={4}>
            <Text color="gray.50">
              Mordomia 
            </Text>
            <Select
              h={8}
              value={mordomyValue}
              maxW={120}
              color="gray.50"
              icon={<FaCaretDown fill="#6AFBFB" />}
              onChange={(e) => setMordomyValue(Number(e.target.value))}
            >
              <option value={2} style={{ backgroundColor: '#171923' }}>Ótimo</option>
              <option value={1} style={{ backgroundColor: '#171923' }}>Bom</option>
              <option value={0} style={{ backgroundColor: '#171923' }}>Ruim</option>
            </Select>
          </Flex>
          <Flex mt={4} justify="flex-end">
            Bônus final:
            <Text ml={2}>{getEvalValue(justificationValue, mordomyValue, marketValue)}</Text>
          </Flex>
        </ModalBody>
        <ModalFooter justifyContent='flex-end'>
          <HStack>
            <Button
              color="gray.50"
              colorScheme="cyan"
              onClick={() => {
                if (justificationValue === undefined || mordomyValue === undefined) return;
                onEvaluatePurchase(
                  [justificationValue, mordomyValue],
                  getEvalValue(justificationValue, mordomyValue, marketValue)
                );
                onClose();
              }
            }>
              Avaliar
            </Button>
            <Button
              bgColor="transparent"
              color="gray.50"
              onClick={onClose}
              _hover={{
                bgColor: 'rgba(255, 255, 255, 0.2)',
                color: 'gray.50'
              }}
            >
              Fechar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}