import {
  Tooltip,
  Button,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Wrap,
  useDisclosure
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import imprevisibilityImg from '../assets/imprevisibility-icon.svg';

interface ImprevisibilityHeaderButtonProps {
  children?: ReactNode;
}

export function ImprevisibilityHeaderButton({ children }: ImprevisibilityHeaderButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Tooltip label="Imprevisibilidades" placement="right">
        <Button variant="unstyled" _hover={{ transform: 'scale(1.2)' }} onClick={onOpen}>
          <Image src={imprevisibilityImg} h={10} margin="0 auto" />
        </Button>
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size="full">
        <ModalOverlay bg="rgba(0, 0, 0, 0.65)" backdropFilter="blur(5px)" />
        <ModalContent bgColor="transparent">
          <ModalCloseButton color="gray.50" />
          <ModalBody>
            <Wrap justify="center" mt={8} >
              {children}
            </Wrap>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="cyan" bgColor="cyan.800" color="gray.50" onClick={onClose}>
              VOLTAR
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
