import {
  Avatar,
  Badge,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { FaBars, FaLock, FaLockOpen } from "react-icons/fa";
import { User } from "../contexts/AuthContext";
import { useGame } from "../hooks/useGame";
import { useGameBoard } from "../hooks/useGameBoard";
import { useGameRoom } from "../hooks/useGameRoom";
import { ConfirmEndgameModal } from "./ConfirmEndgameModal";
import { ConfirmLeaveModal } from "./ConfirmLeaveModal";
import { ManageTeamsModal } from "./ManageTeamsModal";
import { MyTeamModal } from "./MyTeamModal";

interface RightDrawerProps {
  gameStarted: boolean;
  roomId: string;
  user: User;
}

export function RightDrawer({
  roomId, user, gameStarted,
}: RightDrawerProps) {
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const { handleNavigate, handleChangeRoomStatus } = useGameRoom(roomId);
  const { gameRoom } = useGame();
  const { selectedGameBoard } = useGameBoard();

  const isRoomLocked = useMemo(() => gameRoom && gameRoom.roomStatus === 'locked', [gameRoom]);

  return (
    <>
      <IconButton
        aria-label="menu"
        display="flex"
        icon={<FaBars />}
        fontSize="x-large"
        variant="unstyled"
        color="gray.50"
        _hover={{
          color: 'pink.300'
        }}
        onClick={onDrawerOpen}
      />
      <Drawer
        isOpen={isDrawerOpen}
        placement="right"
        onClose={onDrawerClose}
      >
        <DrawerOverlay />
        <DrawerContent bgColor="gray.900">
          <DrawerCloseButton color="gray.50" />
          <DrawerHeader color="gray.50">
            <Flex direction="column">
              <HStack my="1rem">
                <Avatar size="md" name={user.name} src={user.avatar} />
                <Box>
                  <Text color="gray.50">
                    {user.name}
                  </Text>
                  <Text
                    color="gray.300"
                    fontSize="small"
                    display={{ base: 'none', sm: 'block' }}
                  >
                    {user.email}
                  </Text>
                  {user.isAdmin && <Badge>Admin</Badge>}
                </Box>
              </HStack>
            </Flex>
            {gameRoom && user.isAdmin && (
              <HStack>
                <VStack flex={1} align="stretch" spacing={0}>
                  <Text fontSize="sm" color="gray.50">Código da sala</Text>
                  <Text fontSize="sm" color="gray.50" fontWeight="thin">{roomId}</Text>
                </VStack>
                <Tooltip label={isRoomLocked ? 'Sala fechada' : 'Sala aberta'}>
                  <IconButton
                    aria-label="lock room"
                    icon={isRoomLocked ? <FaLock /> : <FaLockOpen />}
                    onClick={() => isRoomLocked ? handleChangeRoomStatus('unlocked') : handleChangeRoomStatus('locked')}
                    variant="unstyled"
                    display="flex"
                    color={isRoomLocked ? 'yellow.50' : 'green.400'}
                  />
                </Tooltip>
              </HStack>
            )}
            <HStack my="0.5rem">
              {user.isAdmin ? <ManageTeamsModal roomId={roomId} /> : <MyTeamModal roomId={roomId} />}
            </HStack>
          </DrawerHeader>

          <DrawerBody>
            {gameStarted && (<Flex direction="column" align="stretch">
              {user.isAdmin && (
                <Button
                  variant="link"
                  position="relative"
                  color={window.location.pathname.includes('/scoreboard') ? 'cyan.200' : 'gray.50'}
                  onClick={() => handleNavigate('/scoreboard')}
                  justifyContent="flex-start"
                  p="0 1rem"
                  mb={2}
                  _before={{
                    ...(window.location.pathname.includes('/scoreboard') && {
                      content: '""',
                      left: 0,
                      position: "absolute",
                      width: '10px',
                      height: '10px',
                      background: 'cyan.200',
                      borderRadius: '50%',
                    })
                  }}
                >
                  Placar geral
                </Button>
              )}
              {selectedGameBoard && selectedGameBoard.spaces.map(space => (
                <Button
                  variant="link"
                  position="relative"
                  whiteSpace="break-spaces"
                  textAlign="left"
                  color={window.location.pathname.includes(space.url) ? 'cyan.200' : 'gray.50'}
                  onClick={() => user.isAdmin ? handleNavigate(space.url) : undefined}
                  justifyContent="flex-start"
                  cursor={user.isAdmin ? 'pointer' : 'default'}
                  p="0 1rem 0.4rem"
                  _hover={{
                    textDecorationLine: user.isAdmin ? 'underline' : 'none',
                  }}
                  _before={{
                    ...(window.location.pathname.includes(space.url) && {
                      content: '""',
                      left: 0,
                      position: "absolute",
                      width: '10px',
                      height: '10px',
                      background: 'cyan.200',
                      borderRadius: '50%',
                    })
                  }}
                >
                  {space.name}
                </Button>
              ))}
            </Flex>)}
          </DrawerBody>

          <DrawerFooter justifyContent="center">
            <VStack w="100%">
              {user.isAdmin && <ConfirmEndgameModal roomId={roomId} />}
              <ConfirmLeaveModal />
            </VStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
