import { Box, Flex, BoxProps, Text } from "@chakra-ui/react";
import { ReactNode } from "toasted-notes/node_modules/@types/react";

interface CardContainerProps extends BoxProps {
  children?: ReactNode;
  cardTitle?: ReactNode | string;
  isBack?: boolean;
}

export function CardContainer({
  children, cardTitle, isBack, ...rest
}: CardContainerProps) {
  const bevel = '20px';
  const dotPos = '-15px';
  return (
    <Box
      position="relative"
      backdropFilter="blur(5px)"
      borderRadius="10px"
      borderWidth="8px"
      borderColor="purple.600"
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      bgColor={isBack ? 'purple.600' : 'rgba(0,0,0,0.5)'}
      boxShadow="8px 8px 8px rgba(0, 0, 0, 0.25)"
      h="100%"
      w="100%"
      sx={{
        backfaceVisibility: 'hidden',
        WebkitBackfaceVisibility: 'hidden',
      }}
      {...rest}
    >
      <Flex justify="space-between" w="100%">
        <Flex>
          {/* <Flex bgColor="purple.600" w="20px" h="50px" align="center" transform="translateY(-50%) rotate(45deg)" transformOrigin="left center" pl={1}>
            <Box w="8px" h="8px" borderRadius="50%" bgColor="cyan.200" />
          </Flex> */}
          <Box
            position="relative"
            align="center"
            w={0}
            h={0}
            borderColor="purple.600"
            borderTopWidth={bevel}
            borderLeftWidth={bevel}
            borderRightColor="transparent"
            borderRightWidth={bevel}
            borderBottomWidth={bevel}
            borderBottomColor="transparent"
          >
            <Box position="absolute" top={dotPos} left={dotPos} w="8px" h="8px" borderRadius="50%" bgColor="cyan.200" />
          </Box>
        </Flex>
        <Flex
          width='80%'
          maxW={350}
          height='16px'
          backgroundColor='purple.600'
          position='relative'
          top="-18px"
          margin='0 auto'
          clipPath='polygon(5% 0%, 95% 0%, 100% 100%, 0 100%)'
          px={4}
          justify="center"
          direction="column"
        >
          <Box
            h="4px"
            w="100%"
            bg="linear-gradient(90deg, #6AFBFB 0%, #D9FFFF 50%, #6AFBFB 100%)"
            borderRadius="4px"
            boxShadow="0px 0px 10px rgba(190, 255, 243, 0.6)"
          />
        </Flex>
        <Flex>
          <Box
            position="relative"
            align="center"
            w={0}
            h={0}
            borderColor="purple.600"
            borderTopWidth={bevel}
            borderRightWidth={bevel}
            borderLeftColor="transparent"
            borderLeftWidth={bevel}
            borderBottomWidth={bevel}
            borderBottomColor="transparent"
          >
            <Box position="absolute" top={dotPos} right={dotPos} w="8px" h="8px" borderRadius="50%" bgColor="cyan.200" />
          </Box>
          {/* <Flex bgColor="purple.600" w="20px" h="50px" align="center" justify="flex-end" transform="translateY(-50%) rotate(-45deg)" transformOrigin="right center" pr={1}>
            <Box w="8px" h="8px" borderRadius="50%" bgColor="cyan.200" />
          </Flex> */}
        </Flex>
      </Flex>
      {children}
      <Flex justify="space-between" w="100%" align="end" justifySelf="flex-end">
        {/* <Flex overflow="hidden" w='40px'>
          <Flex bgColor="purple.600" w="20px" h="50px" align="center" transform="translateY(50%) rotate(-45deg)" transformOrigin="left center" pl={1}>
            <Box w="8px" h="8px" borderRadius="50%" bgColor="cyan.200" />
          </Flex>
        </Flex> */}
        <Box
          position="relative"
          align="center"
          w={0}
          h={0}
          borderColor="purple.600"
          borderBottomWidth={bevel}
          borderLeftWidth={bevel}
          borderRightColor="transparent"
          borderRightWidth={bevel}
          borderTopWidth={bevel}
          borderTopColor="transparent"
        >
          <Box position="absolute" bottom={dotPos} left={dotPos} w="8px" h="8px" borderRadius="50%" bgColor="cyan.200" />
        </Box>
        <Flex w="100%" direction="column" position='relative' bottom="-18px">
          {typeof cardTitle === 'string' ? (
            <Text
              borderTopRadius={8}
              px={6}
              py={1}
              m="0 auto"
              bgColor="purple.600"
              color="gray.50"
              fontFamily="Bebas Neue"
              fontSize="1.5rem"
            >
              {cardTitle}
            </Text>
          ) : cardTitle}
          <Flex
            width='60%'
            height='18px'
            backgroundColor='purple.600'
            margin='0 auto'
            clipPath='polygon(0% 0%, 100% 0%, 95% 100%, 5% 100%)'
            justify="center"
            direction="column"
            px={6}
            pt={1}
          >
            <Box
              h="4px"
              w="100%"
              bg="linear-gradient(90deg, #6AFBFB 0%, #D9FFFF 50%, #6AFBFB 100%)"
              borderRadius="4px"
              boxShadow="0px 0px 10px rgba(190, 255, 243, 0.6)"
            />
          </Flex>
        </Flex>
        <Box
            position="relative"
            align="center"
            w={0}
            h={0}
            borderColor="purple.600"
            borderBottomWidth={bevel}
            borderRightWidth={bevel}
            borderLeftColor="transparent"
            borderLeftWidth={bevel}
            borderTopWidth={bevel}
            borderTopColor="transparent"
          >
            <Box position="absolute" bottom={dotPos} right={dotPos} w="8px" h="8px" borderRadius="50%" bgColor="cyan.200" />
          </Box>
        {/* <Flex overflow="hidden" w='40px' justify="flex-end">
          <Flex bgColor="purple.600" w="20px" h="50px" align="center" justify="flex-end" transform="translateY(50%) rotate(45deg)" transformOrigin="right center" pr={1}>
            <Box w="8px" h="8px" borderRadius="50%" bgColor="cyan.200" />
          </Flex>
        </Flex> */}
      </Flex>
    </Box>
  );
}
