import { useState, useMemo, useCallback, useEffect } from "react";
import { VStack, HStack, Text, Box, IconButton, Flex, Tooltip, Collapse, useDisclosure, Button, Select, SelectProps, Avatar } from "@chakra-ui/react";
import { FaCaretDown, FaCheck, FaCheckCircle, FaRegCircle } from "react-icons/fa";
import { useAuth } from "../hooks/useAuth";
import { useGame } from "../hooks/useGame";
import { format } from "../utils/format";
interface PresentationStartupProps {
  startUpUid: string;
  name: string;
  avatar?: string;
  criterias?: string[];
  isPresenting?: boolean;
  pointsEarned?: number;
  savedEvaluation?: number[];
  onStart: () => void;
  onStop: () => void;
  maxPoints?: number;
  disableEval?: boolean;
  onEval: (evaluation: number[], value: number) => void;
}

export function PresentationStartup({
  name, avatar, startUpUid, isPresenting = false, pointsEarned, maxPoints = 1000, disableEval, onStart, onStop, onEval, savedEvaluation, criterias,
}: PresentationStartupProps) {
  const { isOpen, onToggle } = useDisclosure();
  const { user } = useAuth();
  const { gameRoom, myStartUp } = useGame();

  const [evaluation, setEvaluation] = useState(Array<number>(criterias?.length ?? 0).fill(2));

  useEffect(() => {
    if (savedEvaluation) {
      setEvaluation(savedEvaluation);
    }
  }, [savedEvaluation])

  const borderColor = useMemo(() => ['red.400', 'yellow.400', 'green.400'], []);

  const totalPoints = useMemo(() => {
    return maxPoints * ((60 / (2 * (criterias?.length ?? 1))) * (evaluation.reduce((acc, curr) => acc + curr, 0)) + 40) / 100
  }, [criterias?.length, evaluation, maxPoints]);

  const multiplier = useMemo(() => {
    if (gameRoom.purchases && gameRoom.purchases[startUpUid]) {
      return (gameRoom.purchases?.[startUpUid] || []).reduce((acc, curr) => acc * (1 + curr.bonus / 100), 1);
    }
    return 1;
  }, [gameRoom.purchases, startUpUid]);
  
  const handleEvalChange = useCallback((index: number, value: string) => {
    if (disableEval) {
      return;
    }
    const newEval = [...evaluation];
    newEval[index] = Number(value);
    setEvaluation(newEval)
  }, [disableEval, evaluation]);

  return (
    <Box
      w="100%"
      p={2}
      px={4}
      bgColor="rgba(255,255,255,0.2)"
      backdropFilter="blur(5px)"
      borderRadius={6}
      borderColor={isPresenting ? 'cyan.500' : ''}
      borderWidth={isPresenting ? '2px' : ''}
      mb={2}
    >
      <Flex justify="space-between" align="center">
        <Box
          w={user?.isAdmin ? '100%' : ''}
          onClick={user?.isAdmin ? onToggle : () => { }}
          cursor={user?.isAdmin ? 'pointer' : ''}
        >
          <HStack align="center">
            <Avatar src={avatar} name={name} />
            <Text color="gray.50" fontFamily="Bebas Neue" fontSize="2rem">{name}</Text>
          </HStack>
        </Box>
        {user?.isAdmin && <Tooltip label={isPresenting ? 'Parar apresentação' : 'Selecionar para apresentar'}>
          <IconButton
            aria-label="start presenting"
            color="cyan.200"
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontSize="1.5rem"
            icon={isPresenting ? <FaCheckCircle /> : <FaRegCircle />}
            variant="unstyled"
            _hover={{
              transform: "scale(1.2)"
            }}
            onClick={isPresenting ? onStop : onStart}
          />
        </Tooltip>}
        {!user?.isAdmin && (
          <Flex>
            {isPresenting && <Text fontSize="1.5rem" fontWeight="bold" color="yellow.400">{myStartUp?.uid === startUpUid ? 'É a sua vez!' : 'Apresentando...'}</Text>}
            {!isPresenting && pointsEarned && <Text fontSize="1.5rem" fontWeight="bold" color="gray.50">{`${format(pointsEarned)} pontos`}</Text>}
          </Flex>
        )}
      </Flex>
      {user?.isAdmin && <Collapse in={isOpen}>
        <VStack direction="column" mt={4} mb={2} align="normal">
          {criterias && criterias.map((criteria, i) => (
            <PresentationCriteria
              key={criteria}
              criteria={criteria}
              onChange={(e) => handleEvalChange(i, e.target.value)}
              value={evaluation[i]}
              borderColor={borderColor[evaluation[i]]}
            />
          ))}
          <HStack spacing={6}>
            <Text flex={1} fontFamily="Bebas Neue" color="gray.50" fontSize="1.5rem" textAlign="center">Pontos: {format(Math.round(totalPoints * multiplier))}</Text>
            {disableEval ? (
              <HStack color="green.400">
                <Text color="gray.50" fontWeight="bold">
                  Avaliado
                </Text>
                <FaCheck />
              </HStack>
            ) : (
              <Button disabled={disableEval} colorScheme="cyan" color="gray.50" onClick={() => onEval(evaluation, Math.round(totalPoints * multiplier))}>
                Avaliar
              </Button>
            )}
          </HStack>
        </VStack>
      </Collapse>}
    </Box >
  );
}

interface PresentationCriteriaProps extends SelectProps {
  criteria?: string;
}

function PresentationCriteria({
  criteria, ...rest
}: PresentationCriteriaProps) {
  return (
    <Flex justify="space-between">
      <Text color="gray.50">
        {criteria}
      </Text>
      <Select h={8} maxW={120} color="gray.50" icon={<FaCaretDown fill="#6AFBFB" />} {...rest}>
        <option value={2} style={{ backgroundColor: '#171923' }}>Ótimo</option>
        <option value={1} style={{ backgroundColor: '#171923' }}>Bom</option>
        <option value={0} style={{ backgroundColor: '#171923' }}>Ruim</option>
      </Select>
    </Flex>
  );
}
