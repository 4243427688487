import { ref, update } from "firebase/database";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { database } from "../services/firebase";
import audioStartAsset from '../assets/timer_start.mp3';
import audioEndAsset from '../assets/timer_end.mp3';
import audioIsEndingAsset from '../assets/timer_final_sec.mp3';
import { GameRoom } from "../contexts/GameContext";

export function useTimer(gameRoom: GameRoom, roomId: string) {
  const [seconds, setSeconds] = useState(0);
  const audioStart = useRef(new Audio(audioStartAsset));
  const audioEnd = useRef(new Audio(audioEndAsset));
  const audioIsEnding = useRef(new Audio(audioIsEndingAsset));

  const shouldPlayAudio = !window.location.pathname.includes('quiz');

  const timer = useMemo(() => {
    return gameRoom.timer ?? {};
  }, [gameRoom.timer]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer.status === 'running' && seconds < timer.secondsAmount) {
        setSeconds(prev => (prev ?? 0) + 1);
      }
      if (seconds === 1) {
        if (audioStart.current.paused && shouldPlayAudio) {
          audioStart.current.play();
        }
      }
      if (seconds === timer.secondsAmount - 15) {
        if (audioIsEnding.current.paused && shouldPlayAudio) {
          audioIsEnding.current.play();
        }
      }
      if (seconds === timer.secondsAmount - 3) {
        if (audioEnd.current.paused && shouldPlayAudio) {
          audioEnd.current.play();
        }
      }
      if (timer.status === 'pause') {
        audioIsEnding.current.pause();
        return;
      }
    }, 1000);
    if (timer.status === 'off') {
      setSeconds(0);
      audioEnd.current.pause();
      audioIsEnding.current.pause();
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [seconds, timer.secondsAmount, timer.status]);

  const getFormattedTime = useCallback((seconds: number) => {
    const remainingSeconds = timer.secondsAmount - seconds;
    const minutesDisplay = Math.floor(remainingSeconds / 60).toString().padStart(2, '0');
    const secondsDisplay = Math.floor(remainingSeconds % 60).toString().padStart(2, '0');
    return `${minutesDisplay}:${secondsDisplay}`
  }, [timer.secondsAmount]);

  const handleControlTimer = useCallback(async ({
    action,
    value,
    timerFor,
  }: { action: 'start' | 'pause' | 'stop' | 'reset', value?: number, timerFor?: string }) => {
    const valRef = ref(database, `rooms/${roomId}/timer`);
    switch (action) {
      case 'start':
        await update(valRef, {
          status: 'running',
          ...(value && { secondsAmount: value }),
          ...((timerFor !== undefined) && { timerFor }),
        })
        break;
      case 'pause':
        await update(valRef, {
          status: 'pause',
        })
        break;
      case 'stop':
        await update(valRef, {
          status: 'off',
          timerFor: timerFor ?? '',
        });
        break;
      case 'reset':
        await update(valRef, {
          status: 'off',
          secondsAmount: 0,
          timerFor: '',
        })
        break;
    }
  }, [roomId]);

  const handleAddTime = useCallback(async (amount: number) => {
    const valRef = ref(database, `rooms/${roomId}/timer`);
    await update(valRef, {
      secondsAmount: timer.secondsAmount + amount,
    });
  }, [roomId, timer.secondsAmount]);

  return { seconds, timer, getFormattedTime, handleControlTimer, handleAddTime };
}