import Prismic from '@prismicio/client';
import { RichText } from "prismic-dom";
import { useEffect, useState } from "react";
import { Route, Switch, useParams } from "react-router-dom";
import { useHeader } from "../../hooks/useHeader";
import { IsLoaded } from "../../IsLoaded";
import { ImprevisibilityScreen } from "../../pages/ImprevisibilityScreen";
import { InfoScreen } from "../../pages/InfoScreen";
import { PresentationScreen } from "../../pages/PresentationScreen";
import { getPrismicClient } from "../../services/prismic";

interface ImprevisibilityScreenPrismicResponse {
  data: {
    index: number;
    title: any;
    description: any;
    image: {
      alt: string;
      url: string;
    };
    value: number;
    tip: any;
    scoringcriterias: {
      criteria: any;
    }[];
  };
}

interface ImprevisibilityType {
  title: string;
  description: string;
  image: {
    alt: string;
    url: string;
  };
  value: number;
  index: number;
  tip?: string;
  criterias?: string[];
}

interface ImprevisibilityRoutesProps { }

export function ImprevisibilityRoutes(props: ImprevisibilityRoutesProps) {
  const params = useParams<{ screenId: string }>();
  const [imprevisibility, setImprevisibility] = useState<ImprevisibilityType>();
  useHeader(imprevisibility?.title ?? '');

  useEffect(() => {
    async function fetchImprevisibilityScreen() {
      if (!params.screenId) {
        return;
      }
      const client = getPrismicClient();
      const response = await client.query(Prismic.Predicates.at('document.id', params.screenId));
      const results = (response.results as ImprevisibilityScreenPrismicResponse[]);
      setImprevisibility({
        index: results[0].data.index,
        image: {
          alt: results[0].data.image.alt,
          url: results[0].data.image.url,
        },
        title: RichText.asText(results[0].data.title),
        description: RichText.asHtml(results[0].data.description),
        value: results[0].data.value,
        tip: RichText.asHtml(results[0].data.tip),
        criterias: results[0].data.scoringcriterias.map(criteria => RichText.asText(criteria.criteria)),
      });
    }
    fetchImprevisibilityScreen();
  }, [params, params.screenId]);

  return (
    <IsLoaded externalLoading={!imprevisibility}>
      <Switch>
        <Route path={["/admin/rooms/:id/imprevisibility/:screenId/info", "/rooms/:id/imprevisibility/:screenId/info"]}>
          <InfoScreen info={imprevisibility} />
        </Route>
        <Route path={["/admin/rooms/:id/imprevisibility/:screenId/presentation", "/rooms/:id/imprevisibility/:screenId/presentation"]}>
          <PresentationScreen value={imprevisibility?.value} tip={imprevisibility?.tip} criterias={imprevisibility?.criterias} />
        </Route>
        <Route path={["/admin/rooms/:id/imprevisibility/:screenId/card", "/rooms/:id/imprevisibility/:screenId/card"]}>
          <ImprevisibilityScreen imprevisibilityIndex={(imprevisibility?.index ?? 1) - 1} imprevisibilityTip={imprevisibility?.tip} />
        </Route>
      </Switch>
    </IsLoaded>
  );
}
