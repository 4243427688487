import { Button, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import { FaTimes } from "react-icons/fa";
import { useGameRoom } from "../hooks/useGameRoom";

interface ConfirmEndgameModalProps {
  roomId: string;
}

export function ConfirmEndgameModal({ roomId }: ConfirmEndgameModalProps) {
  const { handleEndGame } = useGameRoom(roomId);
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <>
      <Button
        w="100%"
        leftIcon={<FaTimes />}
        colorScheme="red"
        color="gray.50"
        onClick={onOpen}
        disabled={!roomId}
      >
        ENCERRAR JOGO
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent bgColor="rgba(255,255,255,0.2)">
          <ModalCloseButton color="gray.50" />
          <ModalHeader fontSize="2xl" letterSpacing="1px" color="gray.50" fontFamily="Bebas Neue">ENCERRAR JOGO</ModalHeader>
          <ModalBody color="gray.50">
            <Text textAlign="center">Deseja encerrar essa sala de jogo? Todos os participantes serão desconectados.</Text>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <HStack>
              <Button colorScheme="cyan" onClick={onClose} color="gray.50">CANCELAR</Button>
              <Button colorScheme="red" onClick={handleEndGame}>ENCERRAR</Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
