import {
  Flex,
  Text,
  Box,
  IconButton,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Image,
  AspectRatio,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";
import { onValue, ref } from "@firebase/database";
import { useCallback, useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FaLightbulb } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { evaluatePresentation } from "../api/presentation";
import { Timer } from "../components/Timer";
import { useAuth } from "../hooks/useAuth";
import { useGame } from "../hooks/useGame";
import { useStartUp } from "../hooks/useStartUps";
import { database } from "../services/firebase";
import { PresentationStartup } from "./PresentationStartup";

interface PresentationScreenProps {
  value?: number;
  tip?: string;
  criterias?: string[];
  image?: string;
}

export interface GamePresentations {
  evaluation: string;
  value: number;
}

export function PresentationScreen({
  value = 1000, tip, criterias, image,
}: PresentationScreenProps) {
  const params = useParams<{ screenId: string }>();
  const { user } = useAuth();
  const { startUpsHashMap } = useStartUp();
  const { gameRoom, roomId, timer: { handleControlTimer }, myStartUp } = useGame();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [presentingNow, setPresentingNow] = useState('');

  const myStartupPoints = useMemo(() => gameRoom.presentations?.[myStartUp?.uid ?? '']?.[params.screenId]?.value ?? 0,
    [gameRoom.presentations, myStartUp?.uid, params.screenId]);

  useEffect(() => {
    const valRef = ref(database, `rooms/${roomId}/timer/timerFor`);
    const unsubscribe = onValue(valRef, snapshot => {
      const data = snapshot.val() as string;
      if (data) {
        setPresentingNow(data);
      } else {
        setPresentingNow('');
      }
    });
    return () => unsubscribe();
  }, [roomId]);

  const stopPresentation = useCallback(async () => {
    await handleControlTimer({ action: 'stop', timerFor: '' })
  }, [handleControlTimer]);

  const startPresentation = useCallback(async (startUpUid: string) => {
    await handleControlTimer({ action: 'stop', timerFor: startUpUid })
  }, [handleControlTimer]);

  const handleEvaluate = useCallback(async (startupUid: string, evaluationSelection: number[], value: number) => {
    await evaluatePresentation({
      value,
      roomId,
      screenId: params.screenId,
      evaluationSelection,
      startupUid,
    });
  }, [params.screenId, roomId]);

  return (
    <>
      <Box w="100%" h="100%" my={4}>
        <Box maxW={720} m="0 auto">
          <Box align="center">
            {(user?.isAdmin || (!user?.isAdmin && presentingNow === myStartUp?.uid)) && <Timer size="150px" />}
          </Box>
          <Flex justify="space-between" w="100%" align="center" mt={2}>
            <Box>
              <Text color="gray.50" fontSize="1.25rem" display="inline">
                {user?.isAdmin ? 'Escolha a equipe para apresentar' : 'Equipe apresentando agora'}
              </Text>
            </Box>
            {tip && <Tooltip label="Dica">
              <IconButton
                aria-label="Dica"
                icon={<FaLightbulb />}
                variant="unstyled"
                color="yellow.400"
                fontSize="1.2rem"
                display="flex"
                _hover={{
                  transform: 'scale(1.2)',
                }}
                onClick={onOpen}
              />
            </Tooltip>}
          </Flex>
          <Box w="100%" mt={4}>
            {gameRoom.startUps && user?.isAdmin && gameRoom.startUps.map(s => (
              <PresentationStartup
                key={s.uid}
                startUpUid={s.uid}
                onStart={() => startPresentation(s.uid)}
                name={startUpsHashMap[s.uid]?.name}
                avatar={startUpsHashMap[s.uid]?.logo?.url}
                isPresenting={s.uid === presentingNow}
                onStop={stopPresentation}
                onEval={(evaluation, value) => handleEvaluate(s.uid, evaluation, value)}
                maxPoints={value}
                criterias={criterias}
                disableEval={Boolean(gameRoom.presentations?.[s.uid]?.[params.screenId])}
                pointsEarned={gameRoom.presentations?.[s.uid]?.[params.screenId]?.value}
                savedEvaluation={gameRoom.presentations?.[s.uid]?.[params.screenId]?.evaluation.split('-').map(Number)}
              />
            ))}
            {gameRoom.startUps && !user?.isAdmin && presentingNow && (
              <PresentationStartup
                startUpUid={presentingNow}
                onStart={() => startPresentation(presentingNow)}
                name={startUpsHashMap[presentingNow]?.name}
                avatar={startUpsHashMap[presentingNow]?.logo?.url}
                isPresenting
                onStop={stopPresentation}
                onEval={(evaluation, value) => handleEvaluate(presentingNow, evaluation, value)}
                maxPoints={value}
                criterias={criterias}
                disableEval={Boolean(gameRoom.presentations?.[presentingNow]?.[params.screenId])}
                pointsEarned={gameRoom.presentations?.[presentingNow]?.[params.screenId]?.value}
                savedEvaluation={gameRoom.presentations?.[presentingNow]?.[params.screenId]?.evaluation.split('-').map(Number)}
              />
            )}
            {!user?.isAdmin && !presentingNow && (
              <>
                <Box
                  w="100%"
                  h="70px"
                  px={4}
                  bgColor="rgba(255,255,255,0.2)"
                  backdropFilter="blur(5px)"
                  borderRadius={6}
                  mb={2}
                >
                  <Flex justify="center" align="center" h="100%">
                    <Text color="gray.50" fontWeight="bold" fontSize="1.5rem">Nenhuma</Text>
                  </Flex>
                </Box>
              </>
            )}
            {!user?.isAdmin && <Box color="gray.50">
              <Text display="inline">Sua pontuação nessa apresentação: </Text>
              <Text display="inline" fontWeight="bold" fontSize="1.2rem">{myStartupPoints ? `${myStartupPoints} pontos` : 'Não avaliado'}</Text>
            </Box>}
          </Box>
          <Box my={2}>
            {image && user?.isAdmin && <Accordion allowToggle>
              <AccordionItem>
                <AccordionButton>
                  <Text flex={1} color="gray.50" fontWeight="bold">Imagem de apoio para os participantes</Text>
                  <AccordionIcon color="gray.50" />
                </AccordionButton>
                <AccordionPanel>
                  <Text flex={1} color="gray.50">
                    Os participantes estão vendo a seguinte imagem para auxiliar na apresentação.
                    Lembre-se de explicar o que ela significa.
                  </Text>
                  <AspectRatio ratio={720 / 360}>
                    <Image src={image} borderRadius={6} />
                  </AspectRatio>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>}
            {image && !user?.isAdmin && (
              <AspectRatio ratio={720 / 360}>
                <Image src={image} borderRadius={6} />
              </AspectRatio>
            )}
          </Box>
        </Box>
      </Box>
      {
        tip && <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay bg="rgba(0, 0, 0, 0.65)" backdropFilter="blur(2px)" />
          <ModalContent bgColor="transparent" maxW={350} h={450}>
            <ModalHeader color="gray.50">Dica</ModalHeader>
            <ModalCloseButton color="gray.50" mt={2} />
            <ModalBody
              display="flex"
              bgColor="rgba(255,255,255,0.2)"
              borderRadius={12}
              justifyContent="center"
              alignItems="center"
              p={3}
              m={0}
            >
              <Flex
                h="100%"
                w="100%"
                borderRadius={10}
                borderWidth="2px"
                borderColor="green.200"
                justifyContent="center"
                alignItems="center"
              >
                <Text color="gray.50" textAlign="center" dangerouslySetInnerHTML={{ __html: tip }} />
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      }
    </>
  );
}
