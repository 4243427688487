import {
  Avatar,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { FaUsers } from "react-icons/fa";
import { RoomParticipant } from "../contexts/GameContext";
import { useGame } from "../hooks/useGame";

interface MyTeamModalProps {
  roomId: string;
}

export function MyTeamModal({
  roomId,
}: MyTeamModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { gameRoom, myStartUp } = useGame();
  return gameRoom ? (
    <>
      <Button
        w="100%"
        leftIcon={<FaUsers />}
        colorScheme="cyan"
        color="gray.50"
        onClick={onOpen}
        disabled={!roomId}
        whiteSpace="break-spaces"
      >
        MINHA EQUIPE
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay bg="rgba(0, 0, 0, 0.65)" backdropFilter="blur(2px)" />
        <ModalContent bgColor="rgba(255,255,255,0.2)">
          <ModalHeader fontSize="2xl" letterSpacing="1px" color="gray.50" fontFamily="Bebas Neue">
            <Text display="inline">
              {`MINHA EQUIPE${myStartUp ? ' | ' : ''}`}
            </Text>
            <Text display="inline" color="yellow.50">{myStartUp?.name ?? ''}</Text>
          </ModalHeader>
          <ModalCloseButton color="gray.50" />
          <ModalBody color="gray.50">
            <VStack align="stretch">
              {(Object.entries(gameRoom.participants ?? {}) as [string, RoomParticipant][])
                .filter(([_, participant]) => participant.startUpUid === myStartUp?.uid)
                .map(([key, participant]) => (
                  <HStack key={key} borderColor="cyan.200" borderWidth="1px" p={2} borderRadius={8}>
                    <Avatar boxSize="40px" src={participant.avatar} name={participant.userDisplayName} />
                    <Text flex={1}>{participant.userDisplayName}</Text>
                  </HStack>
                ))}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  ) : null;
}
