import { Center, Heading } from "@chakra-ui/react";

interface ForbiddenProps { }

export function Forbidden(props: ForbiddenProps) {
  return (
    <Center h="100vh" w="100vw">
      <Heading color="gray.50">Forbidden</Heading>;
    </Center>
  );
}
