import { VStack, Text, Button } from "@chakra-ui/react";
import { Link } from "../../components/Link";
import { FormBox } from "../../components/Form/FormBox";
import { Input } from "../../components/Form/Input";
import { useCallback, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { useLocation } from "react-router-dom";

interface SignUpProps { }

export function SignUp(props: SignUpProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const search = useLocation().search;

  const { signUpWithEmailAndPassword } = useAuth();

  const handleSignUp = useCallback(async () => {
    setLoading(true);
    try {
      await signUpWithEmailAndPassword(email, password, name);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [email, name, password, signUpWithEmailAndPassword]);

  return (
    <FormBox>
      <Text
        color="gray.900"
        fontWeight="bold"
        fontSize={24}
        textAlign="center"
      >
        Cadastre-se
      </Text>
      <VStack mt={6} mb={2}>
        <Input
          type="name"
          name="name"
          placeholder="Nome"
          variant="flushed"
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <Input
          type="email"
          name="email"
          placeholder="Email"
          variant="flushed"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <Input
          type="password"
          name="password"
          placeholder="Senha"
          variant="flushed"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
      </VStack>
      <Button my={3} colorScheme="pink" onClick={handleSignUp} isLoading={loading}>
        Cadastrar
      </Button>
      <Link to={`/login${search}`}>
        Voltar
      </Link>
    </FormBox>
  );
}
