import { createContext, ReactNode, useEffect, useMemo, useState } from "react"
import { getPrismicClient } from "../services/prismic";
import Prismic from '@prismicio/client';
import { RichText } from "prismic-dom";

export interface StartUp {
  id: string;
  uid: string;
  name: string;
  logo?: {
    url?: string;
    alt?: string;
  };
  problem: string;
  hypothesis: string;
  idealSolution: string;
  businessModel: string;
  imprevisibilities: {
    description: string;
  }[];
}

type StartUpContextType = {
  startUps: StartUp[];
  startUpsHashMap: Record<string, StartUp>;
}

interface StartupPrismicResponse {
  id: string;
  uid: string;
  data: {
    logo?: {
      url?: string;
      alt?: string;
    };
    name: any;
    problem: any;
    hypothesis: any;
    idealsolution: any;
    businessmodel: any;
    imprevisibilities: {
      description: any;
    }[];
  };
}

export const StartUpContext = createContext({} as StartUpContextType);

export function StartUpProvider({ children }: { children: ReactNode }) {
  const [startUps, setStartUps] = useState<StartUp[]>([]);

  const startUpsHashMap = useMemo(() => {
    if (startUps.length === 0) {
      return {};
    }
    const hashMap = {} as Record<string, StartUp>;
    startUps.forEach(s => {
      if (!hashMap[s.uid]) {
        hashMap[s.uid] = s;
      }
      return;
    });
    return hashMap;
  }, [startUps]);

  useEffect(() => {
    async function fetchStartUps() {
      const client = getPrismicClient();
      const response = await client.query(
        Prismic.Predicates.at('document.type', 'startup'),
      );
      setStartUps((response.results as StartupPrismicResponse[]).map(startup => ({
        id: startup.id,
        uid: startup.uid,
        logo: startup.data.logo,
        name: RichText.asText(startup.data.name),
        problem: RichText.asText(startup.data.problem),
        hypothesis: RichText.asHtml(startup.data.hypothesis),
        idealSolution: RichText.asText(startup.data.idealsolution),
        businessModel: RichText.asText(startup.data.businessmodel),
        imprevisibilities: startup.data.imprevisibilities.map(i => ({
          description: RichText.asHtml(i.description),
        })),
      })));
    }
    fetchStartUps();
  }, []);

  return (
    <StartUpContext.Provider value={{
      startUps,
      startUpsHashMap,
    }}>
      {children}
    </StartUpContext.Provider>
  );
}