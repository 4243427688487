import { Box, Flex, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { useAuth } from "../../hooks/useAuth";

interface ChoiceProps {
  text: string;
  index: number;
  isSelected?: boolean;
  answer?: number;
  isCorrectChoice: boolean;
  onClick?: () => void;
  amountSelected?: number;
}

export function Choice({
  text, index, isSelected, onClick, answer, isCorrectChoice, amountSelected = 0,
}: ChoiceProps) {
  const { user } = useAuth();
  const letters = ['a', 'b', 'c', 'd', 'e', 'f'];

  const answerExists = useMemo(() => answer !== undefined, [answer]);

  const borderStyle = useMemo(() => {
    if (isSelected || ((answerExists || user?.isAdmin) && isCorrectChoice)) {
      return {
        borderColor: "green.100",
        boxShadow: '0px 0px 4px 4px rgba(247, 255, 201, 0.25)',
      }
    }
    if ((answerExists || user?.isAdmin) && !isCorrectChoice) {
      if (answer === index) {
        return {
          borderColor: "red.500",
        }
      }
      return {
        borderColor: "red.400",
      }
    }
    return {
      borderColor: "pink.300",
    }
  }, [answer, answerExists, index, isCorrectChoice, isSelected, user?.isAdmin]);

  const bgColor = useMemo(() => {
    if ((answerExists || user?.isAdmin) && isCorrectChoice) {
      return {
        bgColor: 'green.400',
      }
    }
    if ((answerExists || user?.isAdmin) && !isCorrectChoice) {
      if (answer === index) {
        return {
          bgColor: 'red.400',
        }
      }
      return {
        bgColor: 'none'
      }
    }
    return {
      bgGradient: "linear(to-b, pink.400, purple.600)",
    }
  }, [answer, answerExists, index, isCorrectChoice, user?.isAdmin]);

  return (
    <Box
      display="flex"
      w="100%"
      px="1rem"
      py="0.5rem"
      borderRadius={8}
      {...bgColor}
      textAlign="left"
      borderWidth="2px"
      justifyContent="space-between"
      alignItems="center"
      {...borderStyle}
      transition="filter 0.2s"
      filter={isSelected ? 'brightness(1.1)' : ''}
      _hover={{
        filter: 'brightness(1.1)',
      }}
      onClick={!(answerExists || user?.isAdmin) ? onClick : undefined}
      cursor={!(answerExists || user?.isAdmin) ? 'pointer' : 'auto'}
    >
      <Flex alignItems="center">
        <Text
          as="span"
          fontSize={24}
          fontWeight="bold"
          color={isSelected ? 'yellow.50' : 'gray.50'}
          textTransform="uppercase"
          mr={6}
        >
          {letters[index]}
        </Text>
        <Text
          as="span"
          fontWeight="bold"
          color={isSelected ? 'yellow.50' : 'gray.50'}
          textTransform="uppercase"
        >
          {text}
        </Text>
      </Flex>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minW="40px"
        minH="40px"
        w="40px"
        h="40px"
        borderRadius="50%"
        bgColor={!(answerExists || user?.isAdmin) ? 'pink.300' : 'gray.50'}
      >
        {!(answerExists || user?.isAdmin) && isSelected && <Box
          w="30px"
          h="30px"
          borderRadius="50%"
          bgColor="yellow.50"
        >
        </Box>}
        {(answerExists && !user?.isAdmin) && (
          <Text fontSize={28} color={isCorrectChoice ? 'green.600' : 'red.600'}>
            {isCorrectChoice ? <FaCheck /> : <FaTimes />}
          </Text>
        )}
        {user?.isAdmin && (
          <Text fontSize={20} fontWeight="bold" color={isCorrectChoice ? 'green.600' : 'red.600'}>
            {amountSelected}
          </Text>
        )}
      </Box>
    </Box>
  );
}
