import { Box, Flex } from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { IsLoaded } from "../../IsLoaded";
import { ForgotPassword } from "./ForgotPassword";
import { Login } from "./Login";
import { SignUp } from "./SignUp";

interface LoginRouterProps { }

export function LoginRouter(props: LoginRouterProps) {
  const { user } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const search = useMemo(() => new URLSearchParams(location.search), [location.search]);

  useEffect(() => {
    if (user) {
      history.push(search.get('redirectTo') ?? '/enter-room');
      return;
    }
  }, [history, search, user]);

  return (
    <Flex
      w="100vw"
      h="100vh"
      align="center"
      justify="center"
      bgImage="url(/assets/background-image-login.png)"
      bgSize="cover"
    >
      <IsLoaded>
        <Box margin="auto">
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route path="/login/signup" component={SignUp} />
            <Route path="/login/forgot-password" component={ForgotPassword} />
          </Switch>
        </Box>
      </IsLoaded>
    </Flex>
  );
}
