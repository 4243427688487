interface CardTopNotchProps {
  fill?: string;
}

export function CardTopNotch({fill}: CardTopNotchProps) {
  return (
    <svg height="48" viewBox="0 0 83 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.8726 0H0V54.5H79.6274C75.384 54.5 71.3143 52.8143 68.3137 49.8137L23.1863 4.68629C20.1857 1.68571 16.116 0 11.8726 0Z" fill={fill ?? 'white'} fill-opacity={fill ?? 0.2} />
    </svg>
  );
}
