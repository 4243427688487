import { Box, Flex, Heading } from "@chakra-ui/react";
import Lottie from "react-lottie";
import { CardContainer } from "../components/CardContainer";
import { useHeader } from "../hooks/useHeader";
import { useQuiz } from "../hooks/useQuiz";
import { CardConnector } from "./CardConnector";
import quizAnimation from '../assets/quiz.json';
import { IsLoaded } from "../IsLoaded";

interface QuizInfoScreenProps { }

export function QuizInfoScreen(props: QuizInfoScreenProps) {
  const { quiz, quizLoading } = useQuiz();
  useHeader('QUIZ');

  return (
    <IsLoaded externalLoading={quizLoading}>
      <Flex
        align="center"
        justify="center"
        flex={1}
        px={8}
        pt={1}
        pb={4}
      >
        <Flex mt={8} align="center" justify="space-evenly">
          <CardContainer maxW={300} display={{ base: 'none', md: 'flex' }}>
            <Box m="-2rem auto" position="relative" h={200} maxW={300} left={5}>
              <Lottie
                options={{
                  animationData: quizAnimation,
                  loop: true,
                  autoplay: true,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height="100%"
                width="100%"
              />
            </Box>
          </CardContainer>
          <Flex display={{ base: 'none', md: 'flex' }} justify="space-around" direction="column" alignSelf="stretch" zIndex={1} py={8}>
            <CardConnector />
            <CardConnector />
          </Flex>
          <CardContainer h={300}>
            <Box px={4}>
              <Heading color="gray.50" fontFamily="Bebas Neue">Instruções</Heading>
              {quiz.description && <Box color="gray.50" fontSize="large" dangerouslySetInnerHTML={{ __html: quiz.description }} />}
            </Box>
          </CardContainer>
        </Flex>
      </Flex>
    </IsLoaded>
  );
}


