import { Box, Flex, Grid, GridItem, Heading, HStack, Text } from "@chakra-ui/react";
import { AvatarCircle } from "../components/AvatarCircle";
import { CardTopNotch } from "../components/CardTopNotch";
import { StartUp } from "../contexts/StartUpContext";
import { useAuth } from "../hooks/useAuth";

interface StartupCardProps {
  startUpInfo?: StartUp;
}

export function StartupCard({ startUpInfo }: StartupCardProps) {
  const { user } = useAuth();

  if (!startUpInfo) {
    return null;
  }

  return (
    <Box>
      <Flex mt={7}>
        <HStack
          bgColor={{ base: 'none', sm: "rgba(255,255,255,0.2)" }}
          backdropFilter={{ base: 'none', sm: "blur(5px)" }}
          borderRadius="20px 0 0 0"
          px={4}
          py={2}
          zIndex={10}
        >
          <AvatarCircle
            w={{ base: 20, sm: 24 }}
            h={{ base: 20, sm: 24 }}
            name={startUpInfo.name}
            src={startUpInfo.logo?.url ?? ''}
            my={-8}
          />
          <Heading
            fontSize="large"
            textTransform="uppercase"
            color="gray.50"
          >
            {startUpInfo.name}
          </Heading>
        </HStack>
        <Box display={{ base: 'none', sm: 'block' }}>
          <CardTopNotch />
        </Box>
      </Flex>
      <Box
        bgColor="rgba(255,255,255,0.2)"
        backdropFilter="blur(5px)"
        borderRadius={{ base: "20px", sm: "0 20px 20px 20px" }}
        boxShadow="8px 8px 8px rgba(0, 0, 0, 0.25)"
        px={8}
        pt={10}
        pb={4}
      >
        <Grid templateColumns={{ base: "1fr", sm: "repeat(4, 1fr)" }} columnGap={3} rowGap={{ base: 2, sm: 8 }}>
          <GridItem colSpan={1} alignSelf="center" mt={{ base: 4, sm: 0 }}>
            <Text color="gray.50" fontWeight="bold">PROBLEMA</Text>
          </GridItem>
          <GridItem colSpan={3}>
            <Text color="gray.50">{startUpInfo.problem}</Text>
          </GridItem>
          <GridItem colSpan={1} alignSelf="center" mt={{ base: 4, sm: 0 }}>
            <Text color="gray.50" fontWeight="bold">HIPÓTESE</Text>
          </GridItem>
          <GridItem colSpan={3}>
            <Box color="gray.50" dangerouslySetInnerHTML={{ __html: startUpInfo.hypothesis }} />
          </GridItem>
          <GridItem colSpan={1} alignSelf="center" mt={{ base: 4, sm: 0 }}>
            <Text color="gray.50" fontWeight="bold">SOLUÇÃO IDEAL</Text>
          </GridItem>
          <GridItem colSpan={3}>
            <Text color="gray.50">{startUpInfo.idealSolution}</Text>
          </GridItem>
          <GridItem colSpan={1} alignSelf="center" mt={{ base: 4, sm: 0 }}>
            <Text color="gray.50" fontWeight="bold">REFERÊNCIA DE NEGÓCIO</Text>
          </GridItem>
          <GridItem colSpan={3}>
            <Text color="gray.50">{startUpInfo.businessModel}</Text>
          </GridItem>
          {startUpInfo.imprevisibilities.length > 0 && startUpInfo.imprevisibilities.map((imp, index) => {
            if (user?.isAdmin) {
              return (
                <>
                  <GridItem colSpan={1} alignSelf="center" mt={{ base: 4, sm: 0 }}>
                    <Text color="gray.50" fontWeight="bold">{`IMPREVISIBILIDADE ${index + 1}`}</Text>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <Text color="gray.50" dangerouslySetInnerHTML={{__html: imp.description}} />
                  </GridItem>
                </>
              )
            }
            return null;
          })}
        </Grid>
      </Box>
    </Box>
  );
}
