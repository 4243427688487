import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import Lottie from 'react-lottie';
import { useHistory, useParams } from "react-router-dom";
import radar from '../assets/1666-radar.json';
import warn from '../assets/4386-connection-error.json';
import { CardContainer } from "../components/CardContainer";
import { useHeader } from "../hooks/useHeader";
import { CardConnector } from "./CardConnector";

interface InfoScreenType {
  title?: string;
  description?: string;
  image?: {
    alt?: string,
    url?: string;
  };
}

interface InfoScreenProps {
  info?: Required<InfoScreenType>;
  isLoading?: boolean;
}

export function InfoScreen({
  info, isLoading,
}: InfoScreenProps) {
  const params = useParams<{ screenId: string }>();
  const history = useHistory();

  const [pageInfo, setPageInfo] = useState({} as InfoScreenType);
  useHeader(pageInfo.title ?? '');

  const isImprevisibility = history.location.pathname.includes('imprevisibility');

  useEffect(() => {
    async function fetchInfoScreen() {
      if (!params.screenId) {
        return;
      }
      if (info) {
        setPageInfo({
          title: info.title,
          description: info.description,
          image: info.image,
        });
        return;
      }
    }
    fetchInfoScreen();
  }, [info, params, params.screenId]);

  return (
    <Flex
      flex={1}
      align="center"
      px={8}
      pt={1}
      pb={4}
    >
      <Flex mt={8} align="center" justify="space-evenly">
        <CardContainer maxW={300}>
          <Box m="-2rem auto" position="relative" h={isImprevisibility ? 200 : 300} maxW={300}>
            <Lottie
              options={{
                animationData: isImprevisibility ? warn : radar,
                loop: true,
                autoplay: true,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height="100%"
              width="100%"
            />
            {!isImprevisibility && <Text mt={0} color="cyan.200" position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
              <FaUser />
            </Text>}
          </Box>
        </CardContainer>
        <Flex justify="space-around" direction="column" alignSelf="stretch" zIndex={1} py={8}>
          <CardConnector />
          <CardConnector />
        </Flex>
        <CardContainer>
          <Box px={4}>
            <Heading color="gray.50" fontFamily="Bebas Neue">Instruções</Heading>
            {pageInfo.description && <Box color="gray.50" fontSize="large" dangerouslySetInnerHTML={{ __html: pageInfo.description }} />}
          </Box>
        </CardContainer>
      </Flex>
    </Flex>
  );
}
