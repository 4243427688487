import { Box, VStack, Text, Flex, Fade } from "@chakra-ui/react";
import { ref, set } from "@firebase/database";
import { useEffect, useState, useMemo } from "react";
import { useAuth } from "../hooks/useAuth";
import { useGame } from "../hooks/useGame";
import { useHeader } from "../hooks/useHeader";
import { useStartUp } from "../hooks/useStartUps";
import { database } from "../services/firebase";

interface ScoreboardProps { }

export function Scoreboard(props: ScoreboardProps) {
  const { handleGetStartUpTotalPoints, gameRoom, roomId } = useGame();
  const { startUpsHashMap } = useStartUp();
  const { user } = useAuth();
  const [initialScore, setInitialScore] = useState({} as Record<string, number | undefined>);
  const [finalScore, setFinalScore] = useState({} as Record<string, number | undefined>);
  useHeader('Placar geral');

  useEffect(() => {
    setInitialScore(gameRoom.scoreboard?.previousScore ?? {});
    setFinalScore(gameRoom.scoreboard?.currentScore ?? {});
  }, [gameRoom.scoreboard?.currentScore, gameRoom.scoreboard?.previousScore]);

  useEffect(() => {
    async function saveScores() {
      if (!gameRoom.startUps || !user) {
        return;
      }
      const startUpsScore = Object.fromEntries(gameRoom.startUps.map(startUp => [startUp.uid, handleGetStartUpTotalPoints(startUp.uid)]) ?? []);
      const lastScore = gameRoom.scoreboard?.currentScore ?? {};

      if (!gameRoom.scoreboard) {
        if (user.isAdmin) {
          await set(ref(database, `rooms/${roomId}/scoreboard/currentScore`), startUpsScore);
        }
        return;
      }

      const isChanged = Object.entries(lastScore).some(([startUpUid, score]) => (startUpsScore !== undefined) && (score !== startUpsScore[startUpUid]));
      if (!isChanged) {
        return;
      }
      if (user.isAdmin) {
        await set(ref(database, `rooms/${roomId}/scoreboard/previousScore`), gameRoom.scoreboard.currentScore);
        await set(ref(database, `rooms/${roomId}/scoreboard/currentScore`), startUpsScore);
      }
    }
    saveScores();
  }, [gameRoom.scoreboard, gameRoom.startUps, handleGetStartUpTotalPoints, roomId, user, user?.isAdmin]);

  const startUps = useMemo(() => {
    return [...gameRoom.startUps ?? []].sort((a, b) => (finalScore[b.uid] ?? 0) - (finalScore[a.uid] ?? 0))
  }, [finalScore, gameRoom.startUps])

  const startUpsInitialWidths = useMemo(() => {
    // const values = Object.values(initialScore);
    // const maxValue = Math.max(...(values.filter(Boolean) as number[] ?? []));
    const maxValue = 30000;
    return Object.entries(initialScore).reduce((acc, [startUpUid, score]) => {
      acc[startUpUid] = (score ?? 0) * 100 / maxValue
      return acc;
    }, {} as Record<string, number>);
  }, [initialScore]);

  const startUpsFinalWidths = useMemo(() => {
    const maxValue = 30000;
    // const values = Object.values(finalScore);
    // const maxValue = Math.max(...(values.filter(Boolean) as number[] ?? []));
    return Object.entries(finalScore).reduce((acc, [startUpUid, score]) => {
      acc[startUpUid] = (score ?? 0) * 100 / maxValue
      return acc;
    }, {} as Record<string, number>);
  }, [finalScore]);

  return (
    <Box maxW={980} w="100%" margin="0 auto" pt="1rem">
      <VStack spacing={4} h="100%" align="start">
        {startUps && startUps.map(s =>
          <StartUpScore
            key={s.uid}
            title={startUpsHashMap[s.uid]?.name ?? ''}
            currentScore={finalScore[s.uid] ?? 0}
            previousScore={initialScore[s.uid] ?? 0}
            initialWidth={startUpsInitialWidths[s.uid] ?? 0}
            finalWidth={startUpsFinalWidths[s.uid]}
          />
        )}
      </VStack>
    </Box>
  );
}

interface StartUpScoreProps {
  title: string;
  previousScore: number;
  currentScore: number;
  initialWidth: number;
  finalWidth: number;
}

export function StartUpScore({
  previousScore, currentScore, title, initialWidth, finalWidth,
}: StartUpScoreProps) {
  const [animation, setAnimation] = useState(0);

  const [width, setWidth] = useState<number>(initialWidth);
  const [value, setValue] = useState<number>(previousScore);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setWidth(finalWidth ?? 20);
      setAnimation(1);
      setTimeout(() => {
        setAnimation(2);
        setValue(currentScore);
      }, 3000);
    }, 1500);
    return () => clearTimeout(timeout);
  }, [currentScore, finalWidth]);

  useEffect(() => {
    const step = ((currentScore ?? 0) - (previousScore ?? 0)) / 60;
    if (animation === 1) {
      const interval = setInterval(() => {
        setValue(prev => {
          if (step >= 0) {
            if ((prev ?? 0) + step >= currentScore) {
              return currentScore;
            }
            return Math.round((prev ?? 0) + step);
          } else {
            if ((prev ?? 0) + step <= currentScore) {
              return currentScore;
            }
            return Math.round((prev ?? 0) + step);
          }
        });
        if ((value ?? 0) >= currentScore) {
          clearInterval(interval);
        }
      }, 50);
      return () => clearInterval(interval);
    }
  }, [animation, currentScore, finalWidth, initialWidth, previousScore, value, width]);

  useEffect(() => {
    setWidth(initialWidth);
    setValue(previousScore);
  }, [initialWidth, previousScore]);

  return (value !== undefined) ? (
    <Flex
      align="center"
      w="100%"
      p={1}
      bgColor="purple.500"
      borderRadius={6}
    >
      <Flex flex={1} justify="space-between" align="stretch" h="100%">
        <Box w="20%" pl={2} alignSelf="center">
          <Fade in={animation === 2} transition={{ enter: { duration: 1 } }}>
            <Box>
              <Text color="gray.50" fontFamily="Bebas Neue" fontSize="2rem">{title}</Text>
            </Box>
          </Fade>
        </Box>
        <Box
          flex={1}
          bg="cyan.900"
          borderRadius={6}
          p={1}
        >
          <Box
            w="100%"
            h="100%"
            borderRadius={4}
            bg="repeating-linear-gradient(to right, #1A3A49, #1A3A49 8px, #162F3F 8px, #162F3F 11px)"
            overflow="hidden"
          >
            <Box
              w={`${width}%`}
              transition="all 3s"
              bg="repeating-linear-gradient(to right, #6AFBFB, #6AFBFB 8px, #162F3F 8px, #162F3F 11px)"
              h="100%"
            />
          </Box>
        </Box>
        <Flex
          w="10%"
          ml={2}
          pr={3}
          justify="end"
          align="center"
          color="cyan.200"
          bg="linear-gradient(to bottom, #1A3A49, #1A3A49 50%, #162F3F 50%, #162F3F)"
          bgSize="100% 5px"
          borderRadius={6}
        >
          <Text
            fontSize="3xl"
            fontFamily="Bebas Neue"
            verticalAlign="middle"
            filter="drop-shadow(0px 0px 3px #6AFBFB)"
          >
            {Math.round(value)}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  ) : null
}
