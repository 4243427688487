import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { FaExclamationCircle } from "react-icons/fa";
import { CardContainer } from "../components/CardContainer";
import { StartUp } from "../contexts/StartUpContext";

interface StartupCardProps {
  imprevisibilityId?: number;
  imprevisibilityTip?: string;
  startUpInfo?: StartUp;
}

export function ImprevisibilityCard({
  imprevisibilityId,
  startUpInfo,
  imprevisibilityTip,
}: StartupCardProps) {
  const [showBack, setShowBack] = useState(false);

  if (imprevisibilityId === undefined || !startUpInfo) {
    return null;
  }
  return (
    <Box sx={{ perspective: '1000px' }} borderRadius="20px" backdropFilter="blur(5px)">
      <Box
        position="relative"
        h="400px"
        w="300px"
        transition="transform 0.56s"
        transform={showBack ? 'rotateY(180deg)' : ''}
        sx={{ transformStyle: 'preserve-3d' }}
      >
        <CardContainer
          position="absolute"
          isBack
        >
          <VStack
            as="button"
            id="card-front"
            alignSelf="center"
            align="center"
            justify="center"
            opacity={showBack ? 0 : 1}
            transition={showBack ? "opacity 0.28s cubic-bezier(1,0,0,1)" : "opacity 0.28s cubic-bezier(1,0,0,1)"}
            onClick={() => setShowBack(true)}
            p={2}
          >
            <Text fontSize="4rem" color="gray.50">
              <FaExclamationCircle />
            </Text>
            <Heading fontSize="1.4rem" color="gray.50">
              {`IMPREVISIBILIDADE ${imprevisibilityId + 1}`}
            </Heading>
            <Text color="gray.50">
              Clique para ver o verso
            </Text>
          </VStack>
        </CardContainer>
        <CardContainer
          transform="rotateY(180deg)"
          position="absolute"
          overflow="hidden"
        >
          <VStack
            cursor="pointer"
            id="card-back"
            alignSelf="center"
            align="center"
            onClick={() => setShowBack(false)}
            px={2}
            overflow="auto"
          >
            <Box mt={4}>
              <Text fontSize="4rem" color="gray.50">
                <FaExclamationCircle />
              </Text>
            </Box>
            <Heading fontSize="1.5rem" color="gray.50">
              {startUpInfo.name}
            </Heading>
            <Text color="gray.50" textAlign="justify" dangerouslySetInnerHTML={{ __html: startUpInfo.imprevisibilities[imprevisibilityId].description }} />
          </VStack>
        </CardContainer>
      </Box>
    </Box>
  );
}
