import { Popover, PopoverTrigger, Button, PopoverContent, PopoverHeader, PopoverArrow, PopoverCloseButton, PopoverBody, PopoverFooter, ButtonGroup, IconButton } from "@chakra-ui/react";
import { FaTrash } from "react-icons/fa";

export function RemovePlayerPopover(props: {
    name: string;
    onRemove: () => Promise<void>;
}) {
    return (
    <Popover
        placement='bottom'
        closeOnBlur={false}
    >
        {({ onClose }) => (
        <>
            <PopoverTrigger>
                <IconButton
                    aria-label="remove player"
                    icon={<FaTrash />}
                    variant="link"
                    color="gray.50"
                />
            </PopoverTrigger>
            <PopoverContent color='white' bg='blue.800' borderColor='blue.800'>
                <PopoverHeader pt={4} fontWeight='bold' border='0'>
                    Remover jogador
                </PopoverHeader>
                <PopoverArrow bg='blue.800' />
                <PopoverCloseButton />
                <PopoverBody>
                    Tem certeza que deseja remover {props.name} do jogo?
                    Se ele precisar entrar novamente, basta enviar o link de acesso.
                </PopoverBody>
                <PopoverFooter
                    border='0'
                    display='flex'
                    alignItems='center'
                    justifyContent='flex-end'
                    pb={4}
                >
                    <ButtonGroup size='sm'>
                        <Button colorScheme='grey' onClick={onClose}>Cancelar</Button>
                        <Button colorScheme='red' onClick={props.onRemove}>Remover</Button>
                    </ButtonGroup>
                </PopoverFooter>
            </PopoverContent>
        </>
        )}
    </Popover>
    );
}