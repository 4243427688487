import { Button, Flex } from "@chakra-ui/react";
import { useCallback } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useParams } from "react-router-dom";
import { useGame } from "../../hooks/useGame";
import { useGameBoard } from "../../hooks/useGameBoard";
import { useGameRoom } from "../../hooks/useGameRoom";
import { useQuiz } from "../../hooks/useQuiz";

interface NavigationControlProps {
  navigation: {
    nextScreen?: string;
    previousScreen?: string;
  }
}

interface RouteParams {
  id: string;
  quizId?: string;
  questionId?: string;
}

export function NavigationControl({
  navigation
}: NavigationControlProps) {
  const params = useParams<RouteParams>();
  const { handleNavigate } = useGameRoom(params.id);
  const { timer: { handleControlTimer } } = useGame();
  const { selectedGameBoard } = useGameBoard();
  const { quiz } = useQuiz();

  const handleFindName = useCallback((screenURL: string) => {
    if (screenURL.includes('quiz')) {
      const boardScreenName = selectedGameBoard?.spaces.find(s => s.screenId === params.quizId)?.name ?? '';
      if (screenURL.includes('question')) {
        if (params.questionId) {
          const split = screenURL.split('/');
          const questionIndex = quiz.questionsRef.findIndex(q => q === split[split.length - 1]);
          return `${boardScreenName} Q(${questionIndex + 1}/${quiz.questionsRef.length})`;
        }
        return `${boardScreenName} Q(1/${quiz.questionsRef.length})`;
      }
    }
    return selectedGameBoard?.spaces?.find(s => s.url === screenURL)?.name ?? screenURL;
  }, [params.questionId, params.quizId, quiz, selectedGameBoard?.spaces]);


  return (
    <Flex as="footer" justifySelf="flex-end" justifyContent="space-between" w="100%" px={2} mt={2}>
      {navigation.previousScreen && (
        <Button
          colorScheme="cyan"
          color="gray.50"
          mr="auto"
          onClick={async () => {
            await handleControlTimer({ action: 'stop' });
            await handleNavigate(navigation.previousScreen)
          }}
        >
          <FaChevronLeft />
          {handleFindName(navigation.previousScreen)}
        </Button>
      )}
      {navigation.nextScreen && (
        <Button
          colorScheme="cyan"
          color="gray.50"
          ml="auto"
          onClick={async () => {
            await handleControlTimer({ action: 'stop' });
            await handleNavigate(navigation.nextScreen)
          }}
        >
          {handleFindName(navigation.nextScreen)}
          <FaChevronRight />
        </Button>
      )}
    </Flex>
  );
}
