import { Flex } from "@chakra-ui/react";
import { get, onValue, ref } from "firebase/database";
import { useEffect } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { Header } from "../../components/Header";
import { QuizContextProvider } from "../../contexts/QuizContext";
import { useAuth } from "../../hooks/useAuth";
import { GameRoom } from "../../hooks/useGameRoom";
import { IsLoaded } from "../../IsLoaded";
import { GameOverScreen } from "../../pages/GameOverScreen/GameOverScreen";
import { MarketPlace } from "../../pages/MarketPlace/MarketPlace";
import { Scoreboard } from "../../pages/Scoreboard";
import { UserLobby } from "../../pages/UserRoom/UserLobby";
import { WelcomeScreen } from "../../pages/WelcomeScreen";
import { database } from "../../services/firebase";
import { ImprevisibilityRoutes } from "../Imprevisibility/ImprevisibilityRoutes";
import { PitchRoutes } from "../Pitch/PitchRoutes";
import { UserQuizRoutes } from "../Quiz/UserQuizRoutes";

interface UserRoomRoutesProps { }

interface RouteParams {
  id: string;
}

export function UserRoomRoutes(props: UserRoomRoutesProps) {
  const params = useParams<RouteParams>();
  const history = useHistory();
  const { user } = useAuth();

  useEffect(() => {
    const gameStartedRef = ref(database, `rooms/${params.id}/gameStarted`);
    const currentPathRef = ref(database, `rooms/${params.id}/currentPath`);
    const unsubscribe = onValue(currentPathRef, (snapshot) => {
      const currentPath = snapshot.val() as GameRoom['currentPath'];
      get(gameStartedRef).then(snap => {
        if (snap.val()) {
          history.push(`/rooms/${params.id}${currentPath}`);
        }
      }).catch(console.error);
    });
    return () => unsubscribe();
  }, [history, params.id]);

  // useEffect(() => {
  //   const participantsRef = ref(database, `rooms/${params.id}/participants`);
  //   const unsubscribe = onValue(participantsRef, (snapshot) => {
  //     const data = snapshot.val() as GameRoom['participants'] ?? {};
  //     if (!Object.values(data).find(p => p.userId === user?.id)) {
  //       window.location.href = '/';
  //     }
  //   });
  //   return () => unsubscribe();
  // }, [history, params.id, user?.id]);

  return (
    <IsLoaded>
      <Header />
      <Flex maxW={1120} flex={1} m="0 auto" pb="12" w="100%" flexDir="column" justify="center">
        <Switch>
          <Route exact path="/rooms/:id" component={UserLobby} />
          <Route path="/rooms/:id/welcomescreen/:screenId" component={WelcomeScreen} />
          <Route path="/rooms/:id/pitch/:screenId" component={PitchRoutes} />
          <Route path="/rooms/:id/imprevisibility/:screenId" component={ImprevisibilityRoutes} />
          <Route path="/rooms/:id/quiz/:quizId">
            <QuizContextProvider>
              <UserQuizRoutes />
            </QuizContextProvider>
          </Route>
          <Route path="/rooms/:id/marketplace/:screenId" component={MarketPlace} />
          <Route path="/rooms/:id/endgame" component={GameOverScreen} />
          <Route path="/rooms/:id/scoreboard" component={Scoreboard} />
        </Switch>
      </Flex>
    </IsLoaded>
  );
}