import { Flex } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Loading } from "./components/Loading";
import { useAuth } from "./hooks/useAuth";

interface IsLoadedProps {
  children?: ReactNode;
  externalLoading?: boolean;
}

export function IsLoaded({ children, externalLoading }: IsLoadedProps) {
  const { loading } = useAuth();

  if (loading || externalLoading) {
    return (
      <Flex w="100%" h="100%" align="center" justify="center">
        <Loading />
      </Flex>
    );
  }

  return (
    <>
      {children}
    </>
  );
}
