import Prismic from '@prismicio/client';

export function getPrismicClient(req?: unknown) {
  const prismic = Prismic.client(process.env.REACT_APP_PRISMIC_URL || '', {
    req,
    accessToken: process.env.REACT_APP_PRISMIC_ACCESS_TOKEN,
  });
  return prismic;
}

export type PrismicRichText = {
  type: string;
  text: string;
  spans: any[];
}