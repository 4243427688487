import { ref, remove, set } from "firebase/database";
import { database } from "../services/firebase";

interface EvaluatePresentationParams {
  roomId: string;
  startupUid: string;
  screenId: string;
  evaluationSelection: number[];
  value: number;
}

export async function evaluatePresentation({
  roomId, startupUid, screenId, evaluationSelection, value,
}: EvaluatePresentationParams) {
  const valRef = ref(database, `rooms/${roomId}/presentations/${startupUid}/${screenId}`);
  await set(valRef, {
    evaluation: evaluationSelection.join('-'),
    value,
  });
}

export async function removePresentationEvaluation({
  roomId, startupUid, screenId,
}: Omit<EvaluatePresentationParams, 'evaluationSelection' | 'value'>) {
  const valRef = ref(database, `rooms/${roomId}/presentations/${startupUid}/${screenId}`);
  await remove(valRef);
}