import { useCallback } from "react";
import { useGameBoard } from "./useGameBoard";

export function useNavigation() {
  const { selectedGameBoard } = useGameBoard();
  const gameNavigation = useCallback((currentScreen: string) => {
    if (!selectedGameBoard) {
      return {
        nextScreen: currentScreen,
        previousScreen: currentScreen,
      };
    }
    if (currentScreen === '/') {
      return {
        nextScreen: undefined,
        previousScreen: undefined,
      };
    }
    const spaces = selectedGameBoard.spaces;
    const currentIndex = spaces.findIndex(screen => screen.url === currentScreen);
    return {
      nextScreen: (currentIndex + 1 <= spaces.length - 1) ? spaces[currentIndex + 1].url : undefined,
      previousScreen: (currentIndex - 1 >= 0) ? spaces[currentIndex - 1].url : undefined,
    }
  }, [selectedGameBoard]);

  const quizNavigation = useCallback((questions: string[], currentQuiz: string, currentQuestion?: string) => {
    const quizPath = `/quiz/${currentQuiz}`;
    if (!currentQuestion) {
      return {
        nextScreen: `/quiz/${currentQuiz}/question/${questions[0]}`,
        previousScreen: gameNavigation(quizPath).previousScreen,
      }
    }

    const currentIndex = questions.findIndex(q => q === currentQuestion);
    return {
      nextScreen: (currentIndex + 1 <= questions.length - 1) ? `/quiz/${currentQuiz}/question/${questions[currentIndex + 1]}` : gameNavigation(quizPath).nextScreen,
      previousScreen: (currentIndex - 1 >= 0) ? `/quiz/${currentQuiz}/question/${questions[currentIndex - 1]}` : quizPath,
    }
  }, [gameNavigation]);

  return { gameNavigation, quizNavigation }
}