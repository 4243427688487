import { Link as ChakraLink, LinkProps as ChakraLinkProps } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Link as ReactRouterLink } from "react-router-dom";

interface LinkProps extends ChakraLinkProps {
  to?: string;
  children?: ReactNode;
}

export function Link({
  children, to, ...rest
}: LinkProps) {
  return (
    <ChakraLink
      to={to}
      color="gray.500"
      fontSize={14}
      textAlign="center"
      as={ReactRouterLink}
      {...rest}
    >
      {children}
    </ChakraLink>
  );
}
