import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { FaUsers } from "react-icons/fa";
import { removeParticipant } from "../api/participants";
import { RoomParticipant } from "../contexts/GameContext";
import { useGame } from "../hooks/useGame";
import { useGameRoom } from "../hooks/useGameRoom";
import { useStartUp } from "../hooks/useStartUps";
import { RemovePlayerPopover } from "./RemovePlayerPopover";

interface ManageTeamsModalProps {
  roomId: string;
}

export function ManageTeamsModal({
  roomId,
}: ManageTeamsModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { startUpsHashMap } = useStartUp();
  const { gameRoom } = useGame();
  const { handleSwapUserTeam } = useGameRoom(roomId);
  return gameRoom ? (
    <>
      <Button
        w="100%"
        leftIcon={<FaUsers />}
        colorScheme="cyan"
        color="gray.50"
        onClick={onOpen}
        whiteSpace="break-spaces"
        disabled={!roomId}
      >
        GERENCIAR EQUIPES
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay bg="rgba(0, 0, 0, 0.85)" backdropFilter="blur(2px)" />
        <ModalContent bgColor="rgba(255,255,255,0.3)">
          <ModalHeader fontSize="2xl" letterSpacing="1px" color="gray.50" fontFamily="Bebas Neue">GERENCIAR EQUIPES</ModalHeader>
          <ModalCloseButton color="gray.50" />
          <ModalBody color="gray.50">
            <Accordion allowMultiple>
              {gameRoom.startUps?.map(sU => (
                <AccordionItem key={sU.uid} borderColor="green.200">
                  <AccordionButton>
                    <Text fontWeight="bold" flex={1} textAlign="left">{startUpsHashMap[sU.uid].name}</Text>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel px={0}>
                    <VStack align="stretch">
                      {(Object.entries(gameRoom.participants ?? {}) as [string, RoomParticipant][])
                        .filter(([_, participant]) => participant.startUpUid === sU.uid)
                        .map(([key, participant]) => (
                          <HStack key={key}>
                            <Text flex={1}>{participant.userDisplayName}</Text>
                            <HStack fontSize="0.8rem">
                              <Select
                                placeholder="Enviar para..."
                                h={8}
                                onChange={async e => await handleSwapUserTeam(participant.userId, e.target.value)}
                                color="black"
                                bgColor="gray.50"
                              >
                                {gameRoom.startUps?.filter(s => s.uid !== sU.uid)
                                  .map(s => <option key={s.uid} value={s.uid} style={{ color: 'black' }}>{startUpsHashMap[s.uid].name}</option>)}
                              </Select>
                              <RemovePlayerPopover name={participant.userDisplayName ?? 'Sem nome'} onRemove={() => removeParticipant(roomId, key)} />
                            </HStack>
                          </HStack>
                        ))
                      }
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>

  ) : null;
}
