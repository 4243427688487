import { ScaleFade, Tooltip, IconButton, useDisclosure, Box } from "@chakra-ui/react";
import { useCallback } from "react";
import { FaCopy, FaCheck } from "react-icons/fa";

interface CopyControlProps {
  value: string;
  tooltipText?: string;
}

export function CopyControl({
  value, tooltipText,
}: CopyControlProps) {
  const { isOpen: fadeControl, onOpen, onClose } = useDisclosure();

  const onCopy = useCallback(() => {
    navigator.clipboard.writeText(value);
    onOpen();
    setTimeout(() => {
      onClose();
    }, 3000);
  }, [onClose, onOpen, value]);


  return (
    <Box>
      <ScaleFade style={{ margin: 0 }} initialScale={0.9} in={!fadeControl}>
        <Tooltip maxW={40} display={!fadeControl ? "flex" : "none"} label={tooltipText ? `Copiar ${tooltipText.toLocaleLowerCase()}` : 'Copiar'} placement="top">
          <IconButton
            aria-label="Copy text"
            icon={<FaCopy />}
            variant="unstyled"
            size="sm"
            display={!fadeControl ? "flex" : "none"}
            onClick={onCopy}
          />
        </Tooltip>
      </ScaleFade>
      <ScaleFade style={{ margin: 0 }} initialScale={0.9} in={fadeControl}>
        <Tooltip maxW={40} display={fadeControl ? "flex" : "none"} label={tooltipText ? `${tooltipText} copiado!` : 'Copiado!'} placement="top" closeDelay={1000}>
          <IconButton
            aria-label="Copy text"
            icon={<FaCheck />}
            color="green.400"
            variant="unstyled"
            size="sm"
            display={fadeControl ? "flex" : "none"}
          />
        </Tooltip>
      </ScaleFade>
    </Box>
  )
}