import { Box } from "@chakra-ui/react";

interface CheckBoxProps {
  isChecked?: boolean;
  isDisabled?: boolean;
}

export function CheckBox({
  isChecked, isDisabled,
}: CheckBoxProps) {
  return (
    <Box h="20px" w="20px" bgColor="gray.900" borderWidth={2} borderColor="cyan.200" borderRadius={4} p="2px">
      {isChecked && <Box h="100%" w="100%" bgColor="cyan.200" borderRadius="2px" />}
    </Box>
  );
}
