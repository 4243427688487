import { useEffect } from "react";
import { createContext, ReactNode, useState } from "react";
import { useParams } from "react-router-dom";
import { getPrismicClient } from "../services/prismic";
import Prismic from '@prismicio/client';
import { RichText } from "prismic-dom";
import { useHeader } from "../hooks/useHeader";

interface QuizInfoScreenPrismicResponse {
  id: string;
  uid: string;
  data: {
    description: {
      text: string;
    }[];
    title: {
      text: string;
    }[];
    questions: {
      questionsref: {
        id: string;
      };
    }[];
  };
}

export interface QuestionType {
  id: string;
  body: string;
  choices: {
    text: string;
    isCorrect: boolean;
  }[];
  value?: number;
}

export interface QuizType {
  description: string;
  questionsRef: string[];
  title: string;
}

interface QuizContextType {
  quiz: QuizType;
  questions: QuestionType[];
  quizLoading: boolean;
}

interface QuestionPrismicResponse {
  id: string;
  uid: string;
  data: {
    body: any;
    choices: {
      choicetext: any;
      iscorrect: boolean;
    }[];
  };
}

export const QuizContext = createContext({} as QuizContextType);

export function QuizContextProvider({ children }: { children?: ReactNode }) {
  const params = useParams<{ quizId: string }>();
  const [quiz, setQuiz] = useState({} as QuizContextType['quiz']);
  const [questions, setQuestions] = useState<QuestionType[]>([]);
  const [quizLoading, setQuizLoading] = useState(false);

  useHeader(quiz.title);

  useEffect(() => {
    async function fetchQuiz() {
      if (!params.quizId) {
        return;
      }
      setQuizLoading(true);
      const client = getPrismicClient();
      const response = await client.query(Prismic.Predicates.at('document.id', params.quizId));
      const results = (response.results as QuizInfoScreenPrismicResponse[]);
      setQuiz({
        description: results[0].data.description[0].text,
        title: results[0].data.title[0].text,
        questionsRef: results[0].data.questions.map(q => q.questionsref.id),
      });
    }
    fetchQuiz();
  }, [params.quizId]);

  useEffect(() => {
    async function fetchQuestions() {
      if (!quiz.questionsRef || quiz.questionsRef.length === 0) {
        return;
      }
      const client = getPrismicClient();
      const response = await client.getByIDs(quiz.questionsRef, {});
      const results = (response.results as QuestionPrismicResponse[]);
      setQuestions(results.map(q => ({
        id: q.id,
        body: RichText.asText(q.data.body),
        choices: q.data.choices.map(c => ({
          text: RichText.asText(c.choicetext),
          isCorrect: c.iscorrect,
        }))
      })));
      setQuizLoading(false);
    }
    fetchQuestions();
  }, [quiz]);

  return (
    <QuizContext.Provider value={{ quiz, questions, quizLoading }}>
      {children}
    </QuizContext.Provider>
  )
}