import { useToast } from "@chakra-ui/react";
import { push, child, ref, update, get, set } from "firebase/database";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { database } from "../services/firebase";
import { useAuth } from "./useAuth";
import { useGame } from "./useGame";
import { useGameBoard } from "./useGameBoard";

export interface RoomParticipant {
  userId: string;
  startUpUid: string;
  userDisplayName?: string;
  avatar?: string;
}

export interface GameRoom {
  author: string[];
  roomStatus: 'locked' | 'unlocked';
  gameStarted: boolean;
  gameFinished?: boolean;
  currentPath: string;
  startUps: {
    uid: string;
    maxParticipants: number;
  }[];
  participants?: Record<string, RoomParticipant>;
  gameBoard: string;
}

export function useGameRoom(roomId?: string) {
  const { user } = useAuth();
  const toast = useToast();
  const history = useHistory();
  const { gameRoom } = useGame();
  const { selectedGameBoard, selectGameBoard } = useGameBoard();

  const handleCreateRoom = useCallback(async (selectedStartUps: { uid: string, maxParticipants: number }[], selectedGameBoard: string) => {
    const firebaseRoomKey = push(child(ref(database), 'rooms')).key;
    if (!selectedGameBoard) return;
    const newRoomData = {
      startUpCount: 4,
      title: 'Nova sala',
      author: [user?.id],
      gameStarted: false,
      roomStatus: 'unlocked',
      currentPath: '/',
      timer: {
        secondsAmount: 0,
        status: 'off',
      },
      startUps: selectedStartUps,
      gameBoard: selectedGameBoard,
    } as GameRoom;
    try {
      await update(ref(database), {
        [`rooms/${firebaseRoomKey}`]: newRoomData,
      });
      return firebaseRoomKey;
    } catch (error) {
      console.error(error);
    }
  }, [user?.id]);

  const handleFindGameRoom = useCallback(async () => {
    if (!roomId) {
      console.error('No roomId provided');
      return;
    }
    try {
      const room = await get(child(ref(database), `rooms/${roomId}`));
      if (!room.exists()) {
        toast({
          title: "Sala não encontrada",
          description: "Não encontramos uma sala com esse código =/",
          status: "error",
          position: "top",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      selectGameBoard((room.val() as GameRoom).gameBoard);
    } catch (error) {
      console.error(error);
    }
  }, [roomId, selectGameBoard, toast]);

  const handleUseGameRoom = useCallback(async (preSelectedStartUpUid?: string) => {
    if (!roomId || !user) {
      return;
    }
    try {
      await handleFindGameRoom();
      const room = await get(child(ref(database), `rooms/${roomId}`));
      if (!room.exists()) {
        console.error('No room with this id found!');
        return;
      }
      const roomVal = room.val() as GameRoom;
      if (roomVal.gameFinished) {
        toast({
          title: "Jogo encerrado!",
          description: "Ops! Parece que essa sala de jogo já foi encerrada.",
          status: "error",
          position: "top",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      if (user.isAdmin) {
        const authors = new Set(roomVal.author);
        authors.add(user.id);
        await set(ref(database,`rooms/${roomId}/author`), Array.from(authors));
        history.push(`/admin/rooms/${roomId}`);
        return;
      }
      const participants = Object.entries(roomVal.participants ?? {});
      const isInTeam = participants.find(([_, participant]) => participant.userId === user.id);
      if (isInTeam) {
        history.push(`/rooms/${roomId}`);
        return;
      }
      const startUpHMap = roomVal.startUps.reduce((acc, curr) => {
        acc[curr.uid] = { ...curr, total: 0 };
        return acc;
      }, {} as Record<string, GameRoom["startUps"][0] & { total: number }>);

      participants.forEach(([_, participant]) => {
        startUpHMap[participant.startUpUid].total = startUpHMap[participant.startUpUid].total + 1;
      });

      // const startUpsOppened = Object.entries(startUpHMap).filter(([uid, info]) => info.total < info.maxParticipants);

      // if (startUpsOppened.length === 0) {
      if (roomVal.roomStatus === 'locked') {
        toast({
          // title: "Sala cheia",
          // description: "Ops! Parece que essa sala já está cheia.",
          title: "Sala fechada",
          description: "Ops! Parece que essa sala está fechada.",
          status: "error",
          position: "top",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      // const startUpUid = startUpsOppened.sort(([sUidA, infoA], [sUidB, infoB]) => infoA.total - infoB.total)[0][0];
      const startUpUid = preSelectedStartUpUid ?? Object.entries(startUpHMap).sort(([sUidA, infoA], [sUidB, infoB]) => infoA.total - infoB.total)[0][0];

      await push(child(ref(database), `rooms/${roomId}/participants`), {
        userId: user.id,
        startUpUid,
        userDisplayName: user.name,
        avatar: user.avatar,
      });
      history.push(`/rooms/${roomId}`);
      return;
    } catch (error) {
      console.error(error);
      return;
    }
  }, [handleFindGameRoom, history, roomId, toast, user]);


  const handleStartGame = useCallback(async () => {
    if (!roomId) {
      console.error('No roomId provided');
      return;
    }
    await update(ref(database), {
      [`rooms/${roomId}/gameStarted`]: true,
      [`rooms/${roomId}/currentPath`]: selectedGameBoard?.spaces[0].url,
    });
  }, [roomId, selectedGameBoard?.spaces]);

  const handleChangeRoomStatus = useCallback(async (status: 'locked' | 'unlocked') => {
    if (!roomId) {
      console.error('No roomId provided');
      return;
    }
    await update(ref(database), {
      [`rooms/${roomId}/roomStatus`]: status,
    });
  }, [roomId]);

  const handleNavigate = useCallback(async (to?: string) => {
    if (!roomId || !to) {
      console.error('No roomId provided');
      return;
    }
    try {
      await update(ref(database), { [`rooms/${roomId}/currentPath`]: to });
    } catch (error) {
      console.error(error);
    }
  }, [roomId]);

  const handleFindMyStartup = useCallback(async () => {
    if (!roomId || !user) {
      return;
    }
    try {
      const room = await get(child(ref(database), `rooms/${roomId}`));
      if (!room.exists()) {
        console.error('No room with this id found!');
        return;
      }
      const roomVal = room.val() as GameRoom;
      const participants = Object.entries(roomVal.participants ?? {});
      const isInTeam = participants.find(([_, participant]) => participant.userId === user.id);
      if (isInTeam) {
        return isInTeam[1].startUpUid;
      }
    } catch (error) {
      console.error(error);
      return;
    }
  }, [roomId, user]);

  const handleSwapUserTeam = useCallback(async (participantUid: string, newStartupUid: string) => {
    if (!newStartupUid || !gameRoom || !participantUid || !roomId) {
      return;
    }
    const record = Object.entries(gameRoom.participants ?? {}).find(([key, p]) => p.userId === participantUid);

    await set(ref(database, `rooms/${roomId}/participants/${record?.[0]}/startUpUid`), newStartupUid);

  }, [gameRoom, roomId]);

  const handleEndGame = useCallback(async () => {
    try {
      if (roomId) {
        await set(ref(database, `rooms/${roomId}/gameFinished`), true);
      }
    } catch (err) {
      console.error(err);
    }
  }, [roomId]);

  return {
    handleCreateRoom,
    handleFindGameRoom,
    handleUseGameRoom,
    handleStartGame,
    handleNavigate,
    handleFindMyStartup,
    handleSwapUserTeam,
    handleEndGame,
    handleChangeRoomStatus,
  };
}