import { useCallback, useEffect, useState, useMemo } from "react";
import { Fade, Box, HStack, Switch, Text } from "@chakra-ui/react";
import { useGame } from "../../hooks/useGame";
import { useHeader } from "../../hooks/useHeader";
import { PlacementCard } from "./PlacementCard";
import { onValue, ref, set } from "@firebase/database";
import { database } from "../../services/firebase";
import { useAuth } from "../../hooks/useAuth";
import { useStartUp } from "../../hooks/useStartUps";
import { Scoreboard } from "../Scoreboard";

interface GameOverScreenProps { }

export function GameOverScreen(props: GameOverScreenProps) {
  useHeader('Ranking final');
  const { gameRoom, roomId, handleGetStartUpTotalPoints } = useGame();
  const { startUpsHashMap } = useStartUp();
  const [rankingMode, setRankingMode] = useState('podium');
  const { user } = useAuth();
  const [showPositions, setShowPositions] = useState<string[]>([]);

  const rankedStartUps = useMemo(() => {
    if (gameRoom.startUps) {
      return gameRoom.startUps.map(startup => ({
        uid: startup.uid,
        score: handleGetStartUpTotalPoints(startup.uid),
        name: startUpsHashMap[startup.uid]?.name,
        imgSrc: startUpsHashMap[startup.uid]?.logo?.url ?? '',
      })).sort((a, b) => b.score - a.score);
    }
    return [];
  }, [gameRoom.startUps, handleGetStartUpTotalPoints, startUpsHashMap]);

  useEffect(() => {
    const valRef = ref(database, `rooms/${roomId}/rankingMode`);
    const unsubscribe = onValue(valRef, snapshot => {
      const data = snapshot.val() as 'ranking' | 'podium';
      if (data) {
        setRankingMode(data);
      }
    });
    return () => unsubscribe();
  }, [roomId]);

  useEffect(() => {
    const valRef = ref(database, `rooms/${roomId}/showPodiumPos`);
    const unsubscribe = onValue(valRef, snapshot => {
      const data = snapshot.val() as string;
      if (data) {
        setShowPositions(data.split('-'));
      } else {
        setShowPositions(Array(gameRoom.startUps?.length).fill('n'))
      }
    });
    return () => unsubscribe();
  }, [gameRoom.startUps?.length, roomId]);

  const handleToggleMode = useCallback(async () => {
    await set(ref(database, `rooms/${roomId}/rankingMode`), rankingMode === 'ranking' ? 'podium' : 'ranking');
  }, [rankingMode, roomId]);

  const handleShowPosition = useCallback(async (pos: number) => {
    if (user?.isAdmin) {
      const newShowPos = [...showPositions];
      newShowPos[pos] = 'y';
      await set(ref(database, `rooms/${roomId}/showPodiumPos`), newShowPos.join('-'));
    }
  }, [roomId, showPositions, user?.isAdmin])

  return (
    <>
      {user?.isAdmin && <Box maxW={980} w="100%" margin="1rem auto 0px" pt="1rem">
        <HStack justify="space-between">
          <HStack align="center">
            <Text as="span" color="gray.50">Pódio</Text>
            <Switch isChecked={rankingMode === 'ranking'} colorScheme="cyan" onChange={handleToggleMode} />
            <Text as="span" color="gray.50">Ranking</Text>
          </HStack>
        </HStack>
      </Box>}
      {rankingMode === 'podium' && <Fade in={rankingMode === 'podium'}>
        <Box maxW={980} w="100%" margin="1rem auto" pt="1rem" h={450}>
          <HStack spacing={4} h="100%" align="end" justify="center">
            <PlacementCard startUp={rankedStartUps[1]} position={1} onClick={() => handleShowPosition(1)} show={showPositions[1] === 'y'} />
            <PlacementCard startUp={rankedStartUps[0]} position={0} onClick={() => handleShowPosition(0)} show={showPositions[0] === 'y'} alignSelf="start" />
            <PlacementCard startUp={rankedStartUps[2]} position={2} onClick={() => handleShowPosition(2)} show={showPositions[2] === 'y'} />
          </HStack>
        </Box>
      </Fade>}
      {rankingMode === 'ranking' && <Fade in={rankingMode === 'ranking'}>
        <Scoreboard />
      </Fade>}
    </>
  );
}
