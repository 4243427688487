import { Flex, Text, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import { useHeader } from "../../hooks/useHeader";
import Prismic from '@prismicio/client';
import { getPrismicClient, PrismicRichText } from "../../services/prismic";
import { RichText } from "prismic-dom";
import { useGame } from "../../hooks/useGame";
import { MarketPlaceCardContainers } from "./MarketPlaceCardContainers";
import { StartupInfoCardContainer } from "../../components/StartupInfoCardContainer";
import { database } from "../../services/firebase";
import { ref, update, remove } from "@firebase/database";
import { IsLoaded } from '../../IsLoaded';
import { evaluatePresentation, removePresentationEvaluation } from "../../api/presentation";

interface MarketScreenType {
  title?: string;
  description?: string;
  items: {
    title: string;
    description: string;
    cost: number;
    bonus: number;
    type: string;
  }[];
  value?: number;
}

interface MarketScreenPrismicResponse {
  id: string;
  uid: string;
  data: {
    title: PrismicRichText;
    description: PrismicRichText;
    items: {
      item_title: [PrismicRichText];
      item_description: [PrismicRichText];
      item_cost: number;
      item_bonus: number;
      item_type: string;
    }[];
    value?: number;
  };
}

interface MarketPlaceProps { }

export function MarketPlace(props: MarketPlaceProps) {
  const params = useParams<{ id: string, screenId: string }>();
  const [pageInfo, setPageInfo] = useState<MarketScreenType>();
  const { gameRoom } = useGame();
  const [loading, setLoading] = useState(false);
  
  useHeader(pageInfo?.title ?? '');

  useEffect(() => {
    async function fetchMarketScreen() {
      if (!params.screenId) {
        return;
      }
      setLoading(true);
      const client = getPrismicClient();
      try {
        const response = await client.query(Prismic.Predicates.at('document.id', params.screenId));
        const results = (response.results as MarketScreenPrismicResponse[]);
        setPageInfo({
          title: RichText.asText(results[0].data.title),
          description: RichText.asHtml(results[0].data.description),
          items: results[0].data.items.map(item => {
            return {
              title: item.item_title[0].text,
              description: item.item_description[0].text,
              bonus: item.item_bonus,
              cost: item.item_cost,
              type: item.item_type
            }
          }),
          value: results[0].data.value ?? 1500,
        });
      } catch (error) {
        console.error(error)
      }
      setLoading(false);
    }
    fetchMarketScreen();
  }, [params, params.screenId]);

  const getStartupEvaluation = useCallback((suid: string) => {
    if (!params.screenId) {
      return [];
    }
    return gameRoom.presentations?.[suid]?.[params.screenId]?.evaluation.split('-').map(Number) ?? [];
  }, [gameRoom.presentations, params.screenId]);

  const addPowerUp = useCallback(async (item: MarketScreenType['items'][number], startUpUid: string) => {
    const powerUpsSelected = gameRoom.purchases ?? {};
    if (!powerUpsSelected[startUpUid]?.length) {
      try {
        await remove(ref(database, `rooms/${params.id}/purchases/${startUpUid}`));
      } catch (error) {
        console.error(error);
      }
    }
    const purchase = [...(powerUpsSelected[startUpUid] || []), {...item, marketId: params.screenId}];
    try {
      await update(ref(database), {
        [`rooms/${params.id}/purchases/${startUpUid}`]: purchase,
      }); 
    } catch (error) {
      console.error(error);
    }
  }, [gameRoom.purchases, params.id, params.screenId]);

  const removePowerUp = useCallback(async (item: MarketScreenType['items'][number], startUpUid: string) => {
    const powerUpsSelected = gameRoom.purchases ?? {};
    const startUpPurchase = powerUpsSelected[startUpUid] || [];
    if (!powerUpsSelected[startUpUid]?.length) {
      try {
        await remove(ref(database, `rooms/${params.id}/purchases/${startUpUid}`));
      } catch (error) {
        console.error(error);
      }
    }
    const purchase = startUpPurchase.filter(p => p.title !== item.title && p.marketId === params.screenId);
    try {
      await update(ref(database), {
        [`rooms/${params.id}/purchases/${startUpUid}`]: purchase,
      }); 
    } catch (error) {
      console.error(error);
    }
  }, [gameRoom.purchases, params.id, params.screenId]);

  const handleSelectPowerUp = useCallback((item: MarketScreenType['items'][number], startupUid: string, add: boolean) => {
    if (add) {
      addPowerUp(item, startupUid);
    } else {
      removePowerUp(item, startupUid);
    }
  }, [addPowerUp, removePowerUp]);


  const handleGetBonus = useCallback((startupUid: string) => {
    const powerUpsSelected = gameRoom.purchases ?? {};
    const startUpPurchase = (powerUpsSelected[startupUid] || []).filter(p => p.marketId === params.screenId);
    return (100 * (startUpPurchase.reduce((acc, curr) => acc * (1 + curr.bonus/100), 1) - 1));
  }, [gameRoom.purchases, params.screenId]);

  const handleEvaluatePurchase = useCallback(async (startUpUid: string, evaluationSelection: number[], value: number) => {
      try {
        await evaluatePresentation({
          roomId: params.id,
          screenId: params.screenId,
          evaluationSelection,
          startupUid: startUpUid,
          value,
        });
      } catch (error) {
        console.error(error);
      }
  }, [params.id, params.screenId]);

  return (
    <>
      <IsLoaded externalLoading={loading}>
        <Flex w="100%" maxW={900} flexDir="column" m="1rem auto 0" px={2}>
            {pageInfo && !loading && <VStack spacing={5} mb={4}>
              <Text
                textAlign="center"
                color="gray.50"
                fontFamily="Poppins"
                letterSpacing="0.1rem"
                dangerouslySetInnerHTML={{ __html: pageInfo.description ?? '' }}
              />
              <StartupInfoCardContainer>
                <MarketPlaceCardContainers
                  onSelectPowerUp={handleSelectPowerUp}
                  getBonus={handleGetBonus}
                  onEvaluatePurchase={handleEvaluatePurchase}
                  purchases={gameRoom.purchases}
                  marketItems={pageInfo.items}
                  marketValue={pageInfo.value ?? 1500}
                  getStartupEvaluation={getStartupEvaluation}
                  removeStartupEvaluation={async (sUid: string) => removePresentationEvaluation({
                    roomId: params.id,
                    screenId: params.screenId,
                    startupUid: sUid,
                  })}
                />
              </StartupInfoCardContainer>
            </VStack>}
        </Flex>
      </IsLoaded>
    </>
  );
}
