import { Text, Box, Flex, VStack, List, HStack, Image, Heading } from "@chakra-ui/react";
import { useGame } from "../hooks/useGame";
import { useMemo } from 'react';
import { StartUp } from "../contexts/StartUpContext";
import { CardContainer } from "../components/CardContainer";
import { ListItem } from "./MarketPlace/components/ListItem";
import bonusImg from '../assets/bonus.png';

interface MarketPlaceCardProps {
  startUpInfo?: StartUp;
}

export function MarketPlaceCard({
  startUpInfo,
}: MarketPlaceCardProps) {
  const { gameRoom } = useGame();

  const totalBonus = useMemo(() => {
    const finalPercent = (gameRoom.purchases?.[startUpInfo?.uid ?? ''] || []).reduce((acc, curr) => acc * (1 + curr.bonus / 100), 1);
    return ((finalPercent - 1) * 100).toFixed();
  }, [gameRoom.purchases, startUpInfo?.uid]);

  const purchases = useMemo(() => startUpInfo ? (gameRoom.purchases?.[startUpInfo.uid] || []) : [], [gameRoom.purchases, startUpInfo]);

  return startUpInfo ? (
    <Box>
      <Text textAlign="center" color="gray.50" fontSize="1.5rem">{startUpInfo.name}</Text>
      <Flex mt={7} direction="column" align="center">
        <CardContainer maxW={500}>
          <VStack px={2}>
            {purchases.length ? <List spacing={3} maxH={450} overflow="auto">
              {(gameRoom.purchases?.[startUpInfo.uid] || []).map(item => (
                <ListItem
                  key={item.title}
                  item={item}
                />
              ))}
            </List> : <Box>
              <Text color="gray.50">Nenhum power-up para a equipe</Text>
            </Box>}
          </VStack>
        </CardContainer>
      </Flex>
      <HStack align="center" mt={8} justify="center">
        <Image src={bonusImg} h={8} mt={-2} />
        <Heading fontFamily="Bebas Neue" color="gray.50">Bônus total: {totalBonus} %</Heading>
      </HStack>
    </Box>
  ) : null;
}
