import { Button, Flex, Text } from "@chakra-ui/react";
import { Input } from '../components/Form/Input';
import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { FormBox } from "../components/Form/FormBox";
import { useGameRoom } from "../hooks/useGameRoom";
import { Loading } from "../components/Loading";
import { Header } from "../components/Header";
import { useAuth } from "../hooks/useAuth";
import { Link } from "../components/Link";
import { useLocation } from "react-router-dom";

interface GameRoomProps { }

export function GameRoom(props: GameRoomProps) {
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const [roomId, setRoomId] = useState(searchParams.get('roomId') ?? '');
  const [loading, setLoading] = useState(false);

  const { handleUseGameRoom } = useGameRoom(roomId);

  const { user } = useAuth();

  const handleEnterGameRoom = useCallback(async (sUid?: string) => {
    setLoading(true);
    try {
      await handleUseGameRoom(sUid);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  },[handleUseGameRoom]);

  const handleSubmit = useCallback(async (event: FormEvent) => {
    event.preventDefault();
    await handleEnterGameRoom(searchParams.get('suid') ?? undefined);
  }, [handleEnterGameRoom, searchParams]);

  useEffect(() => {
    if (searchParams.get('roomId')) {
      handleEnterGameRoom(searchParams.get('suid') ?? undefined);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  return (
    <Flex
      w="100vw"
      h="100vh"
      align="center"
      bgImage="url(/assets/background-image-login.png)"
      bgSize="cover"
      direction="column"
    >
      <Header />
      <FormBox onSubmit={handleSubmit}>
        <Text
          color="gray.900"
          fontWeight="bold"
          fontSize={24}
          textAlign="center"
          mb={4}
        >
          Insira o código da sala
        </Text>
        <Input
          name="room-name"
          variant="outline"
          placeholder="Insira aqui o código da sala"
          type="text"
          value={roomId}
          onChange={e => setRoomId(e.target.value)}
        />
        <Button type="submit" my={3} colorScheme="pink" isLoading={loading} spinner={<Loading />}>
          Entrar na sala
        </Button>
        {user?.isAdmin && (
          <Link to="/admin/create-room">
            Criar nova sala
        </Link>
        )}
      </FormBox>
    </Flex>
  );
}
