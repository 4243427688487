import { Flex, Box } from '@chakra-ui/react';

interface CardConnectorProps { }

function Screw() {
  return (
    <Box bgColor="purple.100" m="auto" w={3} h={3} borderRadius="50%" position="relative" _after={{
      content: '""',
      position: "absolute",
      width: '100%',
      height: '2px',
      background: 'gray.600',
      top: '50%',
      transform: 'translateY(-50%) rotate(45deg)',
    }} />
  );
}

export function CardConnector(props: CardConnectorProps) {
  return (
    <Flex direction="row" align="center">
      <Box display="flex" w={6} bg="purple.600" h={6} borderRadius="50%" ml={-4}>
        <Screw />
      </Box>
      <Box w={8} bg="purple.600" h={2} mx={-1} />
      <Box display="flex" w={6} bg="purple.600" h={6} borderRadius="50%" mr={-4}>
        <Screw />
      </Box>
    </Flex>
  );
}
