import { Flex, HStack } from "@chakra-ui/react";
import { StartupInfoCardContainer } from "../components/StartupInfoCardContainer";
import { useHeader } from "../hooks/useHeader";
import { StartupCard } from "./StartupCard";

interface WelcomeScreenProps { }

export function WelcomeScreen(props: WelcomeScreenProps) {
  useHeader('Conheça sua startup');
  return (
    <>
      <Flex maxW={720} w="100%" flexDir="column" m="1rem auto 0" px={2}>
        <HStack>
          <StartupInfoCardContainer>
            <StartupCard />
          </StartupInfoCardContainer>
        </HStack>
      </Flex>
    </>
  );
}
