import { Route, Switch, useParams } from "react-router-dom";
import { useNavigation } from "../../hooks/useNavigation";
import { useQuiz } from "../../hooks/useQuiz";
import { NavigationControl } from "../../pages/AdminRoom/NavigationControl";
import { QuizInfoScreen } from "../../pages/QuizInfoScreen";
import { QuizScreen } from "../../pages/QuizScreen/QuizScreen";

interface QuizRoutesProps { }

export function AdminQuizRoutes(props: QuizRoutesProps) {
  const params = useParams<{ quizId: string, questionId: string }>();
  const { quizNavigation } = useNavigation();
  const { quiz } = useQuiz();

  return (
    <>
      <Switch>
        <Route exact path="/admin/rooms/:id/quiz/:quizId" component={QuizInfoScreen} />
        <Route path="/admin/rooms/:id/quiz/:quizId/question/:questionId" component={QuizScreen} />
      </Switch>
      {quiz.questionsRef && <NavigationControl navigation={quizNavigation(quiz.questionsRef, params.quizId, params.questionId)} />}
    </>
  );
}
