import { Button, Flex, Heading, HStack, VStack } from "@chakra-ui/react";
import { ref, onValue } from "firebase/database";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CopyControl } from "../../components/CopyControl";
import { GameRoom, useGameRoom } from "../../hooks/useGameRoom";
import { useHeader } from "../../hooks/useHeader";
import { useStartUp } from "../../hooks/useStartUps";
import { database } from "../../services/firebase";
import { SelectStartUpCard } from "../SelectStartUpCard";

interface AdminLobbyProps { }
interface AdminLobbyParams {
  id: string;
}

export function AdminLobby(props: AdminLobbyProps) {
  const params = useParams<AdminLobbyParams>();
  useHeader('Aguardando liberação');
  const [participantsWaiting, setParticipantsWaiting] = useState<{ startUpUid: string; userId: string; }[]>([]);
  const [startUps, setStartUps] = useState<GameRoom["startUps"]>([]);
  const { startUps: startUpsInfo } = useStartUp();
  const { handleStartGame } = useGameRoom(params.id);
  const [isStarting, setIsStarting] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);

  useEffect(() => {
    const roomRef = ref(database, `rooms/${params.id}`);
    const unsubscribe = onValue(roomRef, (snapshot) => {
      const data = snapshot.val() as GameRoom;
      setStartUps(data.startUps);
      setGameStarted(data.gameStarted);
      if (data.participants) {
        setParticipantsWaiting(Object.entries(data.participants).map(p => p[1]));
      }
    });
    return () => unsubscribe();
  }, [params.id]);

  const handleStartGameClick = useCallback(async () => {
    setIsStarting(true);
    try {
      await handleStartGame();
    } catch (error) {
      console.error(error);
    } finally {
      setIsStarting(false)
    }
  }, [handleStartGame]);

  return (
    <>
      <Flex
        m="0 auto"
        p="2"
        width="100%"
        maxW={980}
        align="center"
        justify="center"
        flexDirection="column"
      >
        <Heading
          color="yellow.400"
          fontWeight="bold"
          my={8}
          px={2}
          mr="auto"
          size="lg"
        >
          Compartilhe o código de acesso à sala para os membros das equipes e aguarde até que todos entrem na sala:
        </Heading>
        <VStack
          px="8"
          pb="4"
          w="100%"
          mt={2}
          align="center"
          justify="center"
          borderRadius="10"
          bgColor="rgba(255,255,255,0.2)"
          backdropFilter="blur(5px)"
          flexDirection="column"
        >
          <HStack
            borderRadius={4}
            bgColor="gray.50"
            transform="translateY(-50%)"
            alignSelf="flex-end"
          >
            <CopyControl
              value={`${window.location.origin}/enter-room?roomId=${params.id}`}
              tooltipText="Link para a sala"
            />
          </HStack>

          {startUps.map((s, index) => (
            <SelectStartUpCard
              key={s.uid}
              name={`Equipe 0${index + 1}`}
              startUpInfo={{
                id: s.uid,
                name: startUpsInfo.find(st => st.uid === s.uid)?.name,
                uid: s.uid,
                participantCount: s.maxParticipants,
              }}
              onDelete={() => { }}
              onUpdate={() => { }}
              isWaitingPlayers
              participants={participantsWaiting.filter(p => p.startUpUid === s.uid)}
              copyLink={`${window.location.origin}/login?redirectTo=${encodeURIComponent(`/enter-room?roomId=${params.id}&suid=${s.uid}`)}`}
            />
          ))}
          <HStack w="100%" justify="flex-end" py={2}>
            <Button
              bgColor="cyan.600"
              boxShadow="2px 2px 8px rgba(0, 0, 0, 0.25)"
              _hover={{
                bgColor: "cyan.800",
              }}
              color="gray.50"
              disabled={gameStarted}
              isLoading={isStarting}
              onClick={handleStartGameClick}
            >
              {!gameStarted ? 'INICIAR JOGO' : 'JOGO INICIADO'}
            </Button>
          </HStack>
        </VStack>
      </Flex>
    </>
  );
}
