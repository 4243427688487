import { Flex, Avatar, FlexProps, AvatarProps } from "@chakra-ui/react";

interface AvatarCircleProps extends FlexProps {
  name: string;
  src: string;
  icon?: AvatarProps['icon'];
}

export function AvatarCircle({
  name, src, icon, ...rest
}: AvatarCircleProps) {
  return (
    <Flex
      align="center"
      justify="center"
      borderRadius="50%"
      borderWidth="6px"
      borderColor="purple.500"
      {...rest}
    >
      <Avatar w="100%" h="100%" src={src} name={name} icon={icon} color="purple.600" fontWeight="bold" bgColor="gray.50" />
    </Flex>
  );
}
