import { onValue, ref } from "firebase/database";
import { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useGameBoard } from "../hooks/useGameBoard";
import { useStartUp } from "../hooks/useStartUps";
import { useTimer } from "../hooks/useTimer";
import { GamePresentations } from "../pages/PresentationScreen";
import { database } from "../services/firebase";
import { StartUp } from "./StartUpContext";

export interface RoomParticipant {
  userId: string;
  startUpUid: string;
  userDisplayName?: string;
  avatar?: string;
  score?: number;
}

export interface MarketPurchase {
  marketId: string;
  bonus: number;
  cost: number;
  description: string;
  title: string;
  type: string;
}

export type GameRoom = {
  author: string[];
  gameBoard: string;
  gameStarted: boolean;
  gameFinished?: boolean;
  roomStatus?: 'locked' | 'unlocked';
  currentPath: string;
  startUps?: {
    uid: string;
    maxParticipants: number;
  }[];
  imprevisibility?: number;
  scoreboard?: {
    previousScore?: Record<string, number>;
    currentScore?: Record<string, number>;
  };
  participants?: Record<string, RoomParticipant>;
  purchases?: Record<string, MarketPurchase[] | undefined>;
  presentations?: Record<string, Record<string, GamePresentations>>;
  timer: {
    secondsAmount: number;
    status?: string;
    timerFor?: string;
  };
  quiz?: Record<string,
    Record<string,
      Record<string, {
        correct: boolean;
        selected: number;
      }>
    >
  >
}

interface GameContextType {
  gameRoom: GameRoom;
  roomId: string;
  currentScreen: string;
  myStartUp?: StartUp & { totalPoints: number };
  handleGetStartUpTotalPoints: (startUpUid: string) => number;
  timer: ReturnType<typeof useTimer>;
}

export const GameContext = createContext({} as GameContextType);

export function GameContextProvider({ children }: { children?: ReactNode }) {
  const [gameRoom, setGameRoom] = useState({} as GameRoom);
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const { user } = useAuth();
  const { selectGameBoard } = useGameBoard();
  const { startUpsHashMap } = useStartUp();
  const timer = useTimer(gameRoom, params.id);

  const purchasesCost = useMemo(() => {
    return Object.entries(gameRoom.purchases ?? {}).reduce((acc, [uid, items]) => {
      acc[uid] = items ? items.reduce((a, c) => a + c.cost, 0) : 0;
      return acc;
    }, {} as Record<string, number>)
  }, [gameRoom.purchases]);

  // const purchasesBonus = useMemo(() => {
  //   return Object.entries(gameRoom.purchases ?? {}).reduce((acc, [uid, items]) => {
  //     acc[uid] = items ? items.reduce((a, c) => a + c.bonus, 0) : 0;
  //     return acc;
  //   }, {} as Record<string, number>)
  // }, [gameRoom.purchases]);

  const presentationPoints = useMemo(() => {
    return Object.entries(gameRoom.presentations ?? {}).reduce((acc, [uid, items]) => {
      acc[uid] = Object.entries(items).reduce((acc, [_, item]) => acc + item.value, 0);
      return acc;
    }, {} as Record<string, number>)
  }, [gameRoom.presentations]);

  const participantsPoints = useMemo(() => {
    const participantsList = Object.entries(gameRoom.participants ?? {});
    return participantsList.reduce((acc, [_, participant]) => {
      if (participant.startUpUid in acc) {
        acc[participant.startUpUid] = acc[participant.startUpUid] + (participant.score ?? 0);
        return acc;
      }
      acc[participant.startUpUid] = (participant.score ?? 0);
      return acc;
    }, {} as Record<string, number>);
  }, [gameRoom.participants]);

  const handleGetStartUpTotalPoints = useCallback((startUpUid: string) => {
    return (presentationPoints[startUpUid] || 0) - (purchasesCost[startUpUid] || 0) + (participantsPoints[startUpUid] || 0);
  }, [participantsPoints, presentationPoints, purchasesCost]);

  const myStartUp = useMemo(() => {
    if (user && gameRoom.participants && !user.isAdmin) {
      const participantsList = Object.entries(gameRoom.participants ?? {});
      const participant = participantsList.find(([_, participant]) => participant.userId === user.id);
      if (participant) {
        const startUpInfo = startUpsHashMap[participant[1].startUpUid];
        if (startUpInfo) {
          return {
            ...startUpInfo,
            totalPoints: (presentationPoints[startUpInfo.uid] || 0) - (purchasesCost[startUpInfo.uid] || 0) + (participantsPoints[startUpInfo.uid] || 0),
          }
        }
      }
    }
  }, [gameRoom.participants, participantsPoints, presentationPoints, purchasesCost, startUpsHashMap, user]);


  useEffect(() => {
    if (!params.id) {
      return;
    }
    const roomRef = ref(database, `rooms/${params.id}`);
    const unsubscribe = onValue(roomRef, (snapshot) => {
      const data = snapshot.val() as GameRoom | null;
      if (data) {
        setGameRoom(data);
      }
    });
    return () => unsubscribe();
  }, [params.id]);

  useEffect(() => {
    if (gameRoom.gameBoard) {
      selectGameBoard(gameRoom.gameBoard);
    }
  }, [gameRoom.gameBoard, selectGameBoard]);

  useEffect(() => {
    if (gameRoom.gameFinished) {
      history.push(user?.isAdmin ? '/admin/create-room' : '/enter-room');
    }
  }, [gameRoom.gameFinished, history, user?.isAdmin]);

  const currentScreen = useMemo(() => {
    return window.location.pathname.split(params.id)[1];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, window.location.pathname]);

  return (
    <GameContext.Provider value={{
      gameRoom,
      myStartUp,
      roomId: params.id,
      currentScreen,
      handleGetStartUpTotalPoints,
      timer,
    }}>
      {children}
    </GameContext.Provider>
  );
}
