import { Button, Center, Flex, Heading, HStack, IconButton, Select, Text, Tooltip, useToast, VStack } from "@chakra-ui/react";
import { FaPlus } from 'react-icons/fa';
import { get, child, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Header } from "../components/Header";
import { useAuth } from "../hooks/useAuth";
import { GameRoom, useGameRoom } from "../hooks/useGameRoom";
import { database } from "../services/firebase";
import { SelectStartUpCard } from "./SelectStartUpCard";
import { useHeader } from "../hooks/useHeader";
import { useStartUp } from "../hooks/useStartUps";
import { useGameBoard } from "../hooks/useGameBoard";

interface StartUpSelected {
  id: string;
  uid?: string;
  name?: string;
  participantCount?: number;
}

interface CreateRoomProps { }

export function CreateRoom(props: CreateRoomProps) {
  useHeader('Criar sala');
  const history = useHistory();
  const { startUps } = useStartUp();
  const { user } = useAuth();
  const toast = useToast();
  const { handleCreateRoom } = useGameRoom();
  const { selectedGameBoard, gameBoards, selectGameBoard } = useGameBoard();
  const [startUpsSelected, setStartUpsSelected] = useState<StartUpSelected[]>([]);

  useEffect(() => {
    async function findExistingRoom() {
      const roomsRef = await get(child(ref(database), 'rooms'));
      if (roomsRef.exists()) {
        const adminRoom = Object.entries(roomsRef.val() as Record<string, GameRoom>)
          .find(([_, room]) => room.author.includes(user?.id ?? '') && !room.gameFinished);
        if (adminRoom) {
          history.push(`/admin/rooms/${adminRoom[0]}`)
        }
      }
    }
    findExistingRoom()
  }, [history, user?.id]);

  useEffect(() => {
    const roomCount = 4;
    setStartUpsSelected(Array.from({ length: roomCount }, (_, index) => ({
      id: String(index),
      participantCount: 4,
    })));
  }, [])

  const handleAddStartUp = useCallback(() => {
    setStartUpsSelected(prev => {
      const newArray = [...prev];
      newArray.push({
        id: String(new Date().getTime()),
      });
      return newArray;
    });
  }, []);

  const handleDeleteStartUp = useCallback(id => {
    setStartUpsSelected(prev => {
      return prev.filter(item => item.id !== id);
    });
  }, []);

  const handleUpdateStartUp = useCallback(({
    id, uid, name, participantCount,
  }: StartUpSelected) => {
    setStartUpsSelected(prev => prev.map(i => {
      if (i.id === id) {
        return {
          id,
          ...(uid !== undefined ? { uid } : { uid: i.uid }),
          ...(name ? { name } : { name: i.name }),
          ...(participantCount ? { participantCount } : { participantCount: i.participantCount })
        };
      }
      return i;
    }))
  }, []);

  const handleSubmit = useCallback(async () => {
    if (startUpsSelected.length === 0 || startUpsSelected.filter(s => !s.uid || !s.participantCount).length > 0) {
      toast({
        status: 'error',
        title: 'Erro no preenchimento',
        description: 'O preenchimento de startups não foi feito corretamente',
        position: 'top',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (!selectedGameBoard?.id) {
      toast({
        status: 'error',
        title: 'Erro no preenchimento',
        description: 'Selecione um tabuleiro para continuar',
        position: 'top',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    const roomKey = await handleCreateRoom(startUpsSelected.map(s => ({
      uid: s.uid as string,
      maxParticipants: s.participantCount as number,
    })), selectedGameBoard.id);
    history.push(`/admin/rooms/${roomKey}`);
  }, [handleCreateRoom, history, selectedGameBoard?.id, startUpsSelected, toast]);

  return (
    <>
      <Header />
      <Flex
        m="1rem auto"
        p="2"
        width="100%"
        maxW={980}
        align="center"
        justify="center"
        flexDirection="column"
      >
        <Heading
          color="yellow.400"
          fontWeight="bold"
          my={8}
          px={2}
          mr="auto"
          size="lg"
        >
          Defina o tabuleiro, as startups e o número de membros de cada equipe:
        </Heading>
        <Center mb={4}>
          <HStack>
            <Text color="white" fontWeight="bold">Tabuleiro: </Text>
            <Select
              placeholder="Selecione"
              size="sm"
              bgColor="gray.50"
              value={selectedGameBoard?.id}
              onChange={e => selectGameBoard(e.target.value)}
            >
              {gameBoards.map(item => (
                <option key={item.id} value={item.id}>{item.name}</option>
              ))}
            </Select>
          </HStack>
        </Center>
        <VStack
          w="100%"
          px="8"
          py="4"
          align="center"
          justify="center"
          borderRadius="10"
          bgColor="rgba(255,255,255,0.2)"
          flexDirection="column"
        >
          <HStack
            w="100%"
            justify="space-between"
          >
            <Heading
              size="md"
              color="gray.50"
            >
              EQUIPES
            </Heading>
            <Tooltip label="Adicionar startup" placement="top">
              <IconButton
                aria-label="Add startup"
                size="sm"
                icon={<FaPlus />}
                px="2"
                disabled={startUpsSelected.length >= startUps.length}
                display="flex"
                textAlign="center"
                variant="unstyled"
                color="gray.50"
                marginLeft="auto"
                borderRadius="50%"
                bgColor="cyan.600"
                _hover={{
                  bgColor: 'cyan.800'
                }}
                onClick={handleAddStartUp}
              />
            </Tooltip>
          </HStack>
          {startUpsSelected.map((s, index) => (
            <SelectStartUpCard
              key={s.id}
              name={`Equipe 0${index + 1}`}
              startUpInfo={s}
              onDelete={handleDeleteStartUp}
              onUpdate={handleUpdateStartUp}
              forbiddenOptions={startUpsSelected.map(item => item.uid).filter(Boolean) as string[]}
            />
          ))}
          <HStack w="100%" justify="flex-end" py={2} >
            <Button
              bgColor="cyan.600"
              boxShadow="2px 2px 8px rgba(0, 0, 0, 0.25)"
              _hover={{
                bgColor: "cyan.800",
              }}
              color="gray.50"
              onClick={handleSubmit}
            >
              CONFIRMAR
            </Button>
          </HStack>
        </VStack>
      </Flex>
    </>
  );
}
