import {
  Box,
  Button,
  Flex,
  HStack,
  Image, Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay, Text, Tooltip, useDisclosure, WrapItem, 
} from "@chakra-ui/react";
import { FaUsers } from 'react-icons/fa';
import coinImg from '../assets/bluecoin.png';
import rocketImg from '../assets/rocket.png';
import { useAuth } from "../hooks/useAuth";
import { useGame } from "../hooks/useGame";
import { ImprevisibilityCard } from "../pages/ImprevisibilityCard";
import { MarketPlaceCard } from "../pages/MarketPlaceCard";
import { StartupCard } from "../pages/StartupCard";
import { AvatarCircle } from "./AvatarCircle";
import { ImprevisibilityHeaderButton } from "./ImprevisibilityHeaderButton";
import { RightDrawer } from "./RightDrawer";
import { StartupInfoCardContainer } from "./StartupInfoCardContainer";
import { Timer } from "./Timer";

interface HeaderProps { }

export function Header(props: HeaderProps) {
  const { user } = useAuth();
  const { gameRoom, myStartUp, roomId } = useGame();
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const { isOpen: isPowerUpOpen, onOpen: onPowerUpOpen, onClose: onPowerUpClose } = useDisclosure();

  const shouldShowTimer = !window.location.pathname.includes('presentation');

  return user ? (
    <>
      <Flex
        as="header"
        w="100%"
        maxWidth={1480}
        h="20"
        mx="auto"
        px="6"
        align="center"
        justify="space-between"
      >
        {!user.isAdmin ? (
          <>
            {myStartUp ? (
              <Flex alignSelf="flex-start" direction="column" align="center">
                <Box w="14px" h="5px" p={1} bgColor="purple.500" borderTop={4} borderTopColor="purple.500" />
                <Box w="4px" h="20px" p={1} bgColor="purple.500" borderTop={4} borderTopColor="purple.500" />
                <Box w="14px" h="5px" p={1} bgColor="purple.500" borderTop={4} borderTopColor="purple.500" />
                <AvatarCircle
                  as="button"
                  src={myStartUp.logo?.url ?? ''}
                  name={myStartUp.name}
                  w={20}
                  h={20}
                  mt={-1}
                  onClick={onModalOpen}
                />
                {myStartUp && <Flex mt="2" align="center">
                  <Tooltip label="Bluecoins" placement="right">
                    <Image src={coinImg} h={7} />
                  </Tooltip>
                  <Text color="gray.50" pt={1} fontFamily="Bebas Neue" lineHeight="1.5rem" fontSize="1.5rem" ml={1}>
                    $ {new Intl.NumberFormat('pt-BR').format(Math.round(myStartUp.totalPoints))}
                  </Text>
                </Flex>}
                {gameRoom.imprevisibility && myStartUp && <ImprevisibilityHeaderButton>
                  {myStartUp.imprevisibilities
                    .slice(0, gameRoom.imprevisibility)
                    .map((imp, id) =>
                      <WrapItem key={id}>
                        <ImprevisibilityCard startUpInfo={myStartUp} imprevisibilityId={id} />
                      </WrapItem>
                    )}
                </ImprevisibilityHeaderButton>}
                {gameRoom.purchases && (myStartUp.uid in gameRoom.purchases) && <Box mt="2">
                  <Tooltip label="Power-ups" placement="right">
                    <Button variant="unstyled" _hover={{ transform: 'scale(1.2)' }} onClick={onPowerUpOpen}>
                      <Image src={rocketImg} h={8} margin="0 auto" />
                    </Button>
                  </Tooltip>
                </Box>}
              </Flex>
            ) : (
              <div></div>
            )}
          </>
        ) : (
          <>
            {gameRoom && gameRoom.gameStarted ? (
              <Flex alignSelf="flex-start" direction="column" align="center">
                <Box w="14px" h="5px" p={1} bgColor="purple.500" borderTop={4} borderTopColor="purple.500" />
                <Box w="4px" h="20px" p={1} bgColor="purple.500" borderTop={4} borderTopColor="purple.500" />
                <Box w="14px" h="5px" p={1} bgColor="purple.500" borderTop={4} borderTopColor="purple.500" />
                <AvatarCircle
                  as="button"
                  src=""
                  name=""
                  icon={<FaUsers size={40} />}
                  w={20}
                  h={20}
                  mt={-1}
                  onClick={onModalOpen}
                />
                {gameRoom.purchases && <Box mt={4}>
                  <Tooltip label="Power-ups" placement="right">
                    <Button variant="unstyled" _hover={{ transform: 'scale(1.2)' }} onClick={onPowerUpOpen}>
                      <Image src={rocketImg} h={8} margin="0 auto" />
                    </Button>
                  </Tooltip>
                </Box>}
              </Flex>
            ) : <div></div>}
          </>
        )}
        <HStack>
          <Flex align="center" justify="center" mt={10} mr={4}>
            {shouldShowTimer && gameRoom && gameRoom.gameStarted && <Timer />}
          </Flex>
          <RightDrawer
            roomId={roomId}
            gameStarted={gameRoom?.gameStarted ?? false}
            user={user}
          />
        </HStack>
      </Flex>

      <Modal isOpen={isPowerUpOpen} onClose={onPowerUpClose}>
        <ModalOverlay bg="rgba(0, 0, 0, 0.65)" backdropFilter="blur(5px)" />
        <ModalContent bgColor="transparent" maxW={880} mt={0}>
          <ModalCloseButton color="gray.50" />
          <ModalBody>
            <HStack justify="center" margin="0 auto" maxW={880} w="100%">
              <StartupInfoCardContainer>
                <MarketPlaceCard />
              </StartupInfoCardContainer>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isModalOpen} onClose={onModalClose} size="5xl">
        <ModalOverlay bg="rgba(0, 0, 0, 0.65)" backdropFilter="blur(5px)" />
        <ModalContent bgColor="transparent">
          <ModalCloseButton color="gray.50" />
          <ModalBody>
            <HStack margin="0 auto" maxW={900} w="100%">
              <StartupInfoCardContainer>
                <StartupCard />
              </StartupInfoCardContainer>
            </HStack>
          </ModalBody>
          {/* <ModalFooter>
            <Button colorScheme="cyan" bgColor="cyan.800" color="gray.50" onClick={onModalClose}>
              VOLTAR
            </Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </>
  ) : null;
}
