import { Flex } from "@chakra-ui/react";
import { onValue, ref } from "firebase/database";
import { useEffect, useMemo } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { Header } from "../../components/Header";
import { QuizContextProvider } from "../../contexts/QuizContext";
import { GameRoom } from "../../hooks/useGameRoom";
import { useNavigation } from "../../hooks/useNavigation";
import { IsLoaded } from "../../IsLoaded";
import { AdminLobby } from "../../pages/AdminRoom/AdminLobby";
import { NavigationControl } from "../../pages/AdminRoom/NavigationControl";
import { GameOverScreen } from "../../pages/GameOverScreen/GameOverScreen";
import { MarketPlace } from "../../pages/MarketPlace/MarketPlace";
import { Scoreboard } from "../../pages/Scoreboard";
import { WelcomeScreen } from "../../pages/WelcomeScreen";
import { database } from "../../services/firebase";
import { ImprevisibilityRoutes } from "../Imprevisibility/ImprevisibilityRoutes";
import { PitchRoutes } from "../Pitch/PitchRoutes";
import { AdminQuizRoutes } from "../Quiz/AdminQuizRoutes";

interface UserRoomRoutesProps { }

interface RouteParams {
  id: string;
}

export function AdminRoomRoutes(props: UserRoomRoutesProps) {
  const params = useParams<RouteParams>();
  const history = useHistory();

  useEffect(() => {
    const roomRef = ref(database, `rooms/${params.id}/currentPath`);
    const unsubscribe = onValue(roomRef, (snapshot) => {
      const data = snapshot.val() as GameRoom['currentPath'];
      if (data) {
        history.push(`/admin/rooms/${params.id}${data}`);
      }
    });
    return unsubscribe;
  }, [history, params.id]);

  const showNavigationControl = useMemo(() => (
    !history.location.pathname.includes('/quiz') && !history.location.pathname.includes('/scoreboard')
  ), [history.location.pathname]);

  const { gameNavigation } = useNavigation();
  const currentScreen = useMemo(() => {
    return window.location.pathname.split(params.id)[1];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, window.location.pathname]);

  return (
    <IsLoaded>
      <Header />
      <Flex maxW={1120} justify="center" flex={1} m="0 auto" pb="12" w="100%" flexDir="column">
        <Switch>
          <Route exact path="/admin/rooms/:id" component={AdminLobby} />
          <Route path="/admin/rooms/:id/welcomescreen/:screenId" component={WelcomeScreen} />
          <Route path="/admin/rooms/:id/pitch/:screenId" component={PitchRoutes} />
          <Route path="/admin/rooms/:id/imprevisibility/:screenId" component={ImprevisibilityRoutes} />
          <Route path={["/admin/rooms/:id/quiz/:quizId/question/:questionId", "/admin/rooms/:id/quiz/:quizId"]}>
            <QuizContextProvider>
              <AdminQuizRoutes />
            </QuizContextProvider>
          </Route>
          <Route path="/admin/rooms/:id/marketplace/:screenId" component={MarketPlace} />
          <Route path="/admin/rooms/:id/endgame" component={GameOverScreen} />
          <Route path="/admin/rooms/:id/scoreboard" component={Scoreboard} />
        </Switch>
        {showNavigationControl && <NavigationControl navigation={gameNavigation(currentScreen)} />}
      </Flex>
    </IsLoaded>
  );
}