import {
  ListItem as ChakraListItem,
  ListItemProps as ChakraListItemProps,
  VStack, Text, HStack, Heading, Image,
} from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import { CheckBox } from './CheckBox';

import coinImg from '../../../assets/bluecoin.png';
import giftImg from '../../../assets/bonus.png';
import { MarketItem } from '../MarketPlaceCardContainers';

interface ListItemProps extends ChakraListItemProps {
  item: MarketItem;
  isDisabled?: boolean;
  onClickItem?: (item: ListItemProps['item'], add: boolean) => void;
  checked?: boolean;
  finishedPurchasing?: boolean;
}

export function ListItem({
  item, isDisabled, onClickItem, checked, finishedPurchasing, ...rest
}: ListItemProps) {
  const { user } = useAuth();

  const handleClick = useCallback(() => {
    if (!finishedPurchasing) {
      if (onClickItem) {
        onClickItem(item, !checked);
      }
    }
  }, [checked, finishedPurchasing, item, onClickItem]);

  const disabled = useMemo(() => {
    return isDisabled && !checked;
  }, [checked, isDisabled]);

  return (
    <ChakraListItem
      p={4}
      borderRadius={8}
      _hover={{
        backgroundColor: 'rgba(106,251,251,0.2)',
        ...(user?.isAdmin && { cursor: !disabled ? 'pointer' : 'not-allowed' }),
      }}
      bgColor={checked ? 'rgba(106,251,251,0.2)' : ''}
      {...rest}
      onClick={disabled || !user?.isAdmin ? () => ({}) : handleClick}
      opacity={disabled ? 0.4 : 1}
    >
      <VStack align="start" spacing={1}>
        <HStack>
          {user?.isAdmin && <CheckBox isChecked={checked} isDisabled={disabled} />}
          <Heading fontFamily="Bebas Neue" color={checked ? 'cyan.200' : 'gray.50'} fontSize="1.75rem">{item.title}</Heading>
        </HStack>
        <Text color="gray.50" dangerouslySetInnerHTML={{ __html: item.description }} />
        <HStack alignSelf="end" spacing={5}>
          <HStack align="center">
            <Image src={coinImg} h={8} />
            <Text fontFamily="Bebas Neue" color="gray.50">$ {new Intl.NumberFormat('pt-BR').format(item.cost)}</Text>
          </HStack>
          <HStack align="center">
            <Image src={giftImg} h={6} />
            <Text fontFamily="Bebas Neue" color="gray.50">{item.bonus} %</Text>
          </HStack>
        </HStack>
      </VStack>
    </ChakraListItem>
  );
}
