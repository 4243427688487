import { Box, BoxProps } from "@chakra-ui/react";
import Lottie from "react-lottie";
import loadingHorizontal from '../assets/loading-horizontal.json';

interface LoadingProps extends BoxProps { }

export function Loading({
  ...rest
}: LoadingProps) {
  return (
    <Box {...rest}>
      <Lottie
        options={{
          animationData: loadingHorizontal,
          loop: true,
          autoplay: true,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height="100%"
        width="100%"
      />
    </Box>
  );
}
