import { Flex } from "@chakra-ui/react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { Loading } from "../components/Loading";
import { useAuth } from "../hooks/useAuth";

interface PrivateRouteProps extends RouteProps { }

export function PrivateRoute({ component, ...rest }: PrivateRouteProps) {
  const { user, loading } = useAuth();

  if (user?.isAdmin) {
    return (
      <Route {...rest} component={component} />
    );
  }

  if (loading) {
    return (
      <Flex w="100vw" h="100vh" align="center" justify="center">
        <Loading />
      </Flex>
    )
  }

  return (
    <Redirect to="/forbidden" />
  );
}
