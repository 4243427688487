import { createContext, ReactNode, useCallback, useEffect, useState } from "react";
import { getPrismicClient } from "../services/prismic";
import Prismic from '@prismicio/client';
import { RichText } from "prismic-dom";

export type ScreenType = {
  type: string;
  screenId: string;
  name: string;
  url: string;
};

export type GameBoardContextType = {
  gameBoards: {
    name: string;
    id: string;
  }[];
  selectedGameBoard?: {
    spaces: ScreenType[];
    id: string;
  };
  selectGameBoard: (boardId: string) => Promise<void>;
};

interface GameBoardsPrismicResponse {
  data: {
    title: any;
    spaces: {
      name: any;
      screen: {
        id: string;
        type: string;
      }
    }[];
  }
  id: string;
}
interface GameBoardContextProviderProps {
  children?: ReactNode;
}

function createURL(type: string, id: string) {
  return `/${type}/${id}`;
}

function generateGameBoard(screens: GameBoardsPrismicResponse['data']['spaces']): ScreenType[] {
  const gameBoard: ScreenType[] = [];
  screens.forEach(s => {
    if (s.screen.type === 'imprevisibility') {
      gameBoard.push({
        screenId: `${s.screen.id}/info`,
        type: s.screen.type,
        name: `${RichText.asText(s.name)} (Info)`,
        url: createURL(s.screen.type, `${s.screen.id}/info`),
      }, {
        screenId: `${s.screen.id}/card`,
        type: s.screen.type,
        name: `${RichText.asText(s.name)} (Card)`,
        url: createURL(s.screen.type, `${s.screen.id}/card`),
      }, {
        screenId: `${s.screen.id}/presentation`,
        type: s.screen.type,
        name: `${RichText.asText(s.name)} (Apresentação)`,
        url: createURL(s.screen.type, `${s.screen.id}/presentation`),
      });
      return;
    }
    if (s.screen.type === 'pitch') {
      gameBoard.push({
        screenId: `${s.screen.id}/info`,
        type: s.screen.type,
        name: `${RichText.asText(s.name)} (Info)`,
        url: createURL(s.screen.type, `${s.screen.id}/info`),
      }, {
        screenId: `${s.screen.id}/presentation`,
        type: s.screen.type,
        name: `${RichText.asText(s.name)} (Apresentação)`,
        url: createURL(s.screen.type, `${s.screen.id}/presentation`),
      });
      return;
    }
    gameBoard.push({
      screenId: s.screen.id,
      type: s.screen.type,
      name: RichText.asText(s.name),
      url: createURL(s.screen.type, s.screen.id),
    });
  });
  gameBoard.push({
    screenId: '',
    type: '',
    name: 'Ranking final',
    url: '/endgame',
  })
  return gameBoard;
}

export const GameBoardContext = createContext({} as GameBoardContextType);

export function GameBoardContextProvider({
  children,
}: GameBoardContextProviderProps) {
  const [selectedGameBoard, setSelectedGameBoard] = useState<GameBoardContextType['selectedGameBoard']>();
  const [gameBoards, setGameBoards] = useState<GameBoardContextType['gameBoards']>([]);

  useEffect(() => {
    async function fetchGameBoards() {
      const client = getPrismicClient();
      const response = await client.query(Prismic.Predicates.at('document.type', 'gameboard'), {
        fetch: ['gameboard.title', 'gameboard.id'],
      });
      const results = response.results as Omit<GameBoardsPrismicResponse, 'spaces'>[];
      setGameBoards(results.map(r => ({
          name: RichText.asText(r.data.title),
          id: r.id,
        })));
    }
    fetchGameBoards();
  }, []);

  const handleSelectGameBoard = useCallback(async (boardId: string) => {
    const client = getPrismicClient();
    const response = await client.query(Prismic.Predicates.at('document.id', boardId));
    const results = response.results as GameBoardsPrismicResponse[];

    if (results.length > 0) {
      setSelectedGameBoard({
        id: boardId,
        spaces: generateGameBoard(results[0].data.spaces),
      });
    }
  }, []);

  useEffect(() => {
    handleSelectGameBoard(gameBoards[0]?.id);
  }, [gameBoards, handleSelectGameBoard]);

  return (
    <GameBoardContext.Provider value={{
      selectedGameBoard,
      gameBoards,
      selectGameBoard: handleSelectGameBoard,
    }}>
      {children}
    </GameBoardContext.Provider>
  );
}