import { Button, Text, VStack } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { FormBox } from "../../components/Form/FormBox";
import { Input } from "../../components/Form/Input";
import { Link } from "../../components/Link";

interface ForgotPasswordProps { }

export function ForgotPassword(props: ForgotPasswordProps) {
  const search = useLocation().search;
  
  return (
    <FormBox>
      <Text
        color="gray.900"
        fontWeight="bold"
        fontSize={24}
        textAlign="center"
      >
        Esqueceu sua senha?
      </Text>
      <VStack mt={6} mb={2}>
        <Text align="center">Digite abaixo seu e-mail e enviaremos um e-mail para você inserir uma nova senha.</Text>
        <Input
          type="email"
          name="email"
          placeholder="Email"
          variant="outline"
        />
      </VStack>
      <Button my={3} colorScheme="pink" onClick={() => { }}>
        Enviar
      </Button>
      <Link to={`/login${search}`}>
        Voltar
      </Link>
    </FormBox>
  );
}
