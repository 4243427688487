import { HStack, VStack, List, Heading, Image, Button, useDisclosure, Tooltip, Box } from "@chakra-ui/react";
import { CardContainer } from "../../components/CardContainer";
import { StartUp } from "../../contexts/StartUpContext";
import { useAuth } from "../../hooks/useAuth";
import { ListItem } from "./components/ListItem";
import coinImg from '../../assets/bluecoin.png';
import bonusImg from '../../assets/bonus.png';
import { MarketPurchase } from "../../contexts/GameContext";
import { useState, useEffect, useMemo } from "react";
import { MarketPlaceScoreModal } from "./components/MarketplacePurchaseScoreModal";
import { FaHistory } from "react-icons/fa";

export interface MarketItem {
  title: string;
  description: string;
  cost: number;
  bonus: number;
  type: string;
}

interface MarketPlaceCardContainersProps {
  startUpInfo?: StartUp & { totalPoints: number };
  onSelectPowerUp: (item: MarketItem, startupUid: string, add: boolean) => void;
  onEvaluatePurchase: (startupUid: string, evalDisc: number[], value: number) => Promise<void>;
  purchases?: Record<string, MarketPurchase[] | undefined>;
  getBonus: (startupUid: string) => number;
  marketItems: MarketItem[];
  marketValue: number;
  getStartupEvaluation: (suid: string) => number[];
  removeStartupEvaluation: (suid: string) => Promise<void>;
}


export function MarketPlaceCardContainers({
  startUpInfo, onSelectPowerUp, onEvaluatePurchase, purchases = {}, getBonus, marketItems, marketValue, getStartupEvaluation, removeStartupEvaluation,
}: MarketPlaceCardContainersProps) {
  const { user } = useAuth();
  const [startUpPurchase, setStartUpPurchase] = useState<MarketPurchase[]>();
  const {isOpen, onClose, onOpen} = useDisclosure();

  useEffect(() => {
    if (!startUpInfo) return;
    
    setStartUpPurchase(purchases[startUpInfo.uid] || undefined);
  }, [purchases, startUpInfo]);

  const purchaseIsFinished = useMemo(() => {
    if (startUpInfo) {
      return getStartupEvaluation(startUpInfo?.uid ?? '').length > 0;
    } 
    return false
  }, [getStartupEvaluation, startUpInfo]);

  return startUpInfo ? <VStack spacing={6}>
    {user?.isAdmin && <Heading fontSize={56} fontFamily="Bebas Neue" color="gray.50">{startUpInfo.name}</Heading>}
    <HStack spacing={4} align="start">
      <CardContainer id="talents" h="100%">
        <VStack px={2}>
          <List spacing={3} maxH={450} overflow="auto">
            {marketItems.filter((_, index) => index % 2 === 0).map(item => (
              <ListItem
                key={item.title}
                item={item}
                onClickItem={(item: MarketItem, add: boolean) => onSelectPowerUp(item, startUpInfo.uid, add)}
                isDisabled={user?.isAdmin && ((startUpInfo.totalPoints - item.cost < 0) || purchaseIsFinished)}
                checked={startUpPurchase?.find(p => p.title === item.title) ? true : false}
                finishedPurchasing={purchaseIsFinished}
              />
            ))}
          </List>
        </VStack>
      </CardContainer>
      <CardContainer id="services" h="100%">
        <VStack px={2}>
          <List spacing={3} maxH={450} overflow="auto">
            {marketItems.filter((_, index) => index % 2 !== 0).map(item => (
              <ListItem
                key={item.title}
                item={item}
                onClickItem={(item: MarketItem, add: boolean) => onSelectPowerUp(item, startUpInfo.uid, add)}
                isDisabled={user?.isAdmin && ((startUpInfo.totalPoints - item.cost < 0) || purchaseIsFinished)}
                checked={startUpPurchase?.find(p => p.title === item.title) ? true : false}
                finishedPurchasing={purchaseIsFinished}
              />
            ))}
          </List>
        </VStack>
      </CardContainer>
    </HStack>
    <HStack align="center">
      <Image src={coinImg} h={10} mb={2} />
      <Heading fontFamily="Bebas Neue" color="gray.50">Total disponível: $ {new Intl.NumberFormat('pt-BR').format(startUpInfo.totalPoints)}</Heading>
    </HStack>
    <HStack align="center">
      <Image src={bonusImg} h={10} mb={2} />
      <Heading fontFamily="Bebas Neue" color="gray.50">Bônus acumulado: {getBonus(startUpInfo.uid).toFixed()} %</Heading>
    </HStack>
    {user?.isAdmin && (
      <HStack>
        <Button
          color="gray.50"
          colorScheme="purple"
          onClick={onOpen}
          isDisabled={purchaseIsFinished}
        >
          {purchaseIsFinished ? 'COMPRA AVALIADA' : 'AVALIAR COMPRA'}
        </Button>
        <Button
          color="gray.50"
          colorScheme="purple"
          onClick={() => removeStartupEvaluation(startUpInfo.uid)}
        >
          <Tooltip label="Resetar" placement="right">
            <Box>
              <FaHistory />
            </Box>
          </Tooltip>
        </Button>
      </HStack>
    )}
    <MarketPlaceScoreModal
      isOpen={isOpen}
      onClose={onClose}
      startupName={startUpInfo.name}
      onEvaluatePurchase={(evalDisc: number[], value: number) => onEvaluatePurchase(startUpInfo.uid, evalDisc, value)}
      marketValue={marketValue}
      getSavedEval={() => getStartupEvaluation(startUpInfo.uid)}
    />
  </VStack> : null
}
