import { Box, Button, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { FormBox } from "../../components/Form/FormBox";
import { Input } from "../../components/Form/Input";
import googleLogo from '../../assets/google-logo.svg';
import { Link } from "../../components/Link";
import { useLocation } from "react-router-dom";

export function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const search = useLocation().search;

  const { signInWithGoogle, signInWithCredentials } = useAuth();

  const handleLogin = useCallback(async () => {
    await signInWithGoogle()
  }, [signInWithGoogle]);

  return (
    <FormBox>
      <Text
        color="gray.900"
        fontWeight="bold"
        fontSize={24}
        textAlign="center"
      >
        Faça o seu login
      </Text>
      <VStack mt={6} mb={2}>
        <Input
          name="email"
          placeholder="Email"
          type="email"
          variant="flushed"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <Input
          name="password"
          placeholder="Senha"
          type="password"
          variant="flushed"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
      </VStack>
      <Button my={3} colorScheme="pink" onClick={() => signInWithCredentials(email, password)}>
        Entrar
      </Button>
      <Link to={`/login/forgot-password${search}`}>
        Esqueci minha senha
      </Link>
      <Link to={`/login/signup${search}`}>
        Cadastrar
      </Link>
      <Box
        position="relative"
        textAlign="center"
        my={2}
        _before={{
          content: '""',
          width: "35%",
          borderBottomWidth: "1px",
          position: "absolute",
          margin: "0 16px 0",
          left: 0,
          top: "50%",
        }}
        _after={{
          content: '""',
          width: "35%",
          borderBottomWidth: "1px",
          position: "absolute",
          margin: "0 16px 0",
          right: 0,
          top: "50%",
        }}
        color="gray.400"
      >
        ou
      </Box>
      <Button variant="solid" bgColor="gray.50" boxShadow="md" my={2} onClick={handleLogin}>
        <HStack>
          <Image src={googleLogo} />
          <Text>
            Faça login com google
          </Text>
        </HStack>
      </Button>
    </FormBox>
  )
}